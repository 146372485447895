import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Form, Modal, DatePicker, Input, InputNumber } from 'antd';
import { NotificationInstance } from 'antd/lib/notification/interface';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IBill } from '@entities/bill';

import { Currency, enumLabel as currencyLabel } from '@enums/currency';
import { BillStatus } from '@enums/bill-status';

dayjs.extend(utc);

interface IBillModal {
    userId: string;
    userLogin: string;
    billId: string | undefined;
    onSave: (value: IBill) => void;
    onCancel: () => void;
    api: NotificationInstance;
}

const BillModal = (props: IBillModal) => {
    const { TextArea } = Input;

    const [form] = Form.useForm();
    const d = useAppDispatch();

    const { userLogin, userId, billId, onSave, onCancel, api } = props;

    const [bill, setBill] = useState<IBill>();

    useEffect(() => {
        form.setFieldsValue({
            id: billId,
            userId: userId,
            currency: Currency.Rub,
            status: BillStatus.Completed,
        });

        if (billId) {
            serverFetch(`bills/${billId}`, { method: 'GET' })
                .then((data: IBill) => {
                    setBill(data);

                    form.setFieldsValue({
                        billOn: data.billOn && dayjs(data.billOn).utc(true).set('hour', 0).set('minute', 0).set('second', 0),
                        comment: data.comment,
                        totalPrice: data.totalPrice,
                    });
                })
                .catch((ex) => {
                    exception(api, 'Ошибка получения балансов', ex, () => d(userLoaded(undefined)));
                });
        }
    }, []);

    const onSaveBill = (entity: IBill) => {
        entity.billOn = dayjs(entity.billOn).utc(true).set('hour', 0).set('minute', 0).set('second', 0);
        onSave(entity);
    };

    return (
        <Modal
            destroyOnClose={true}
            width={650}
            title={billId ? `Изменить счет "${bill?.fullNumber}"` : `Выставить новый счет для "${userLogin}"`}
            open={true}
            okText='ОК'
            onOk={() => {
                form.submit();
            }}
            onCancel={() => onCancel()}
        >
            <Form
                colon={false}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{ marginTop: 20 }}
                form={form}
                onFinish={onSaveBill}
            >
                <Form.Item hidden name='id'>
                    <Input />
                </Form.Item>
                <Form.Item hidden name='userId'>
                    <Input />
                </Form.Item>
                <Form.Item hidden name='status'>
                    <Input />
                </Form.Item>
                <Form.Item label='Нименование' name='comment' rules={[{ required: true, message: 'Укажите нименование' }]}>
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item label='Дата счета' name='billOn' rules={[{ required: true, message: 'Укажите дату счета' }]}>
                    <DatePicker format={'DD.MM.YYYY'} />
                </Form.Item>
                <Form.Item label='Сумма' name='totalPrice' wrapperCol={{ span: 9 }} rules={[{ required: true, message: 'Укажите сумму' }]}>
                    <InputNumber
                        precision={2}
                        decimalSeparator=','
                        min={0}
                        style={{ width: '100%' }}
                        addonAfter={currencyLabel(Currency.Usd)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default BillModal;
