import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';

import { Modal, Table, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception, securityRestriction } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { IContact } from '@entities/contact';

import { serverFetch } from '@src/core/server';

interface IContactGroup {
    warehouseCode: string;
    cityName: string;
    contacts: Array<IContact>;
}

const Contacts = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [headers, setHeaders] = useState<Array<IContactGroup>>();
    const [currentContact, setCurrentContact] = useState<IContact>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        init();
    }, [refreshRequired]);

    const init = () => {
        setHeaders(undefined);

        setLoading(true);

        serverFetch('contacts', { method: 'GET' })
            .then((data) => {
                setHeaders(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения контактов', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onDelete = () => {
        if (!currentContact) return;

        serverFetch(`contacts/${currentContact.id}`, { method: 'DELETE' })
            .then(() => {
                setSelectedIds([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления контакта', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Добавить',
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageContacts)) {
                                securityRestriction(api, [Permission.ManageContacts]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageContacts) ? 'Изменить' : 'Информация о контакте'
                        }`,
                        key: 'edit',
                        disabled: !currentContact,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentContact?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentContact,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteContact)) {
                                securityRestriction(api, [Permission.DeleteContact]);
                                return;
                            }

                            modal.confirm({
                                title: `Удалить контакт "${currentContact?.fullName}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const onSelectContactsChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length === 1) {
            if (headers) {
                headers.map((h) => {
                    h.contacts.map((b) => {
                        if (b.id === selectedRowKeys[0]) {
                            setCurrentContact(b);
                        }
                    });
                });
            }
        } else {
            setCurrentContact(undefined);
        }
    };

    const expandedContacts = (record: IContactGroup) => {
        const columns: ColumnsType<IContact> = [
            {
                title: 'Должность',
                dataIndex: 'workingPosition',
                width: 200,
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 300,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={record.contacts}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectContactsChange,
                    columnWidth: 32,
                    type: 'radio',
                }}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IContactGroup> = [
            {
                title: '',
                dataIndex: 'warehouseCode',
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    if (!record.warehouseCode) return '';

                    return `${record.warehouseCode} (${record.cityName})`;
                },
            },
        ];

        return (
            <Table
                rowKey='warehouseCode'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => expandedContacts(record),
                    defaultExpandAllRows: true,
                    columnWidth: 32,
                }}
                dataSource={headers}
                pagination={false}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 182px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {/*   {renderFilter()} */}
            {headers && renderTable()}

            {contextHolder}
            {modalContextHolder}
        </div>
    );
};

export default Contacts;
