import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import PhoneInputFormItem from '@controls/phone-input-form-item/phone-input-form-item';

import { Input, InputNumber, Space, Button, Form, Select, AutoComplete, Tooltip, Flex, Switch, Divider, notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';

import { Permission, hasPermission } from '@enums/permission';
import { getEnumList } from '@extensions/utils';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IConsignee } from '@entities/consignee';
import { ICity } from '@entities/city';
import { IUserFilter } from '@entities/user-filter';
import { IUser } from '@entities/user';
import { ITag } from '@entities/tag';
import { IWarehouse } from '@entities/warehouse';
import { ICountry } from '@entities/country';
import { IConsigneeTariffSetting } from '@entities/consignee-tariff-setting';

import { UserType } from '@enums/user-type';
import { ConsigneeType, enumLabel as consigneeEnumLabel } from '@enums/consignee-type';
import { DeliveryType, enumLabel as deliveryEnumLabel } from '@enums/delivery-type';
import { WeightType, enumLabel as weightTypeLabel } from '@enums/weight-type';
import { WarehouseType } from '@enums/warehouse-type';

import { IEnumItem } from '@enums/enum-item';

const Consignee = ({ ...props }) => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const { TextArea } = Input;

    const [form] = Form.useForm();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<IConsignee>({
        id: undefined,
        userId: undefined,
        type: ConsigneeType.Main,
        deliveryType: DeliveryType.OnHand,
        cityId: undefined,
        isArchived: false,
        tariffSettings: [],
    });

    const [consigneeTypes] = useState(getEnumList(ConsigneeType, consigneeEnumLabel));
    const [deliveryTypes] = useState(getEnumList(DeliveryType, deliveryEnumLabel));
    const [loading, setLoading] = useState<boolean>(false);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [tags, setTags] = useState<Array<ITag>>([]);
    const [users, setUsers] = useState<Array<IUser>>([]);
    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([]);
    const [weightTypes] = useState<Array<IEnumItem>>(getEnumList(WeightType, weightTypeLabel));

    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageConsignees));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const userFilter: IUserFilter = { isActive: undefined /*true*/, isArchived: false, types: [UserType.Supplier] };

            const promises = [
                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('users', { method: 'GET', queryParams: userFilter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('tags', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения тегов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('warehouses', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения складов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries', { method: 'GET', queryParams: { withTransportOnly: true } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`consignees/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения грузополучателя', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0]);
                setUsers(result[0][1]);
                setTags(result[0][2]);

                const srvWarehouses: Array<IWarehouse> = result[0][3] || [];
                setWarehouses(srvWarehouses.filter((w) => w.type == WarehouseType.Transit));

                const countries = result[0][4] as Array<ICountry>;

                const consignee = result[0][5] as IConsignee;
                if (consignee) {
                    const settings: Array<IConsigneeTariffSetting> = [];

                    countries.map((c) => {
                        let setting = consignee.tariffSettings.find((s) => s.countryId === c.id);
                        if (setting) {
                            setting.countryName = c.name;
                        } else {
                            setting = {
                                id: undefined,
                                consigneeId: undefined,
                                countryId: c.id,
                                weightType: WeightType.Volume,
                                countryName: c.name,
                            };
                        }

                        settings.push(setting);
                    });

                    setEntity({ ...consignee, tariffSettings: settings });
                } else {
                    const settings: Array<IConsigneeTariffSetting> = [];
                    countries.map((c) => {
                        const setting: IConsigneeTariffSetting = {
                            id: undefined,
                            consigneeId: undefined,
                            countryId: c.id,
                            weightType: WeightType.Volume,
                            countryName: c.name,
                        };

                        settings.push(setting);
                    });

                    setEntity({ ...entity, tariffSettings: settings });
                }

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    const onSave = () => {
        setLoading(true);

        serverFetch(`consignees`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения грузополучателя', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRestore = () => {
        serverFetch(`consignees/${id}/restore`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка восстановления грузополучателя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderSettings = () => {
        return entity.tariffSettings.map((s) => {
            return (
                <Form.Item key={s.countryId} label={s.countryName}>
                    <Flex gap='middle'>
                        <Tooltip title='Тип веса'>
                            <Form.Item
                                initialValue={s.weightType}
                                name={`weightType${s.countryId}`}
                                style={{ marginBottom: 0, width: 150 }}
                            >
                                <Select
                                    showSearch
                                    onChange={(value) => {
                                        const settings = [...entity.tariffSettings];
                                        const setting = settings.find((e) => e.countryId === s.countryId);

                                        if (setting) setting.weightType = value;

                                        setEntity({ ...entity, tariffSettings: settings });
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    filterSort={(a, b) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={weightTypes.map((t) => {
                                        return { value: t.value, label: t.label };
                                    })}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Flex>
                </Form.Item>
            );
        });
    };

    return (
        <>
            <FormHeader
                title={`${id ? (isViewOnly ? 'Информация о грузополучателе' : 'Изменить грузополучателя') : 'Добавить грузополучателя'}`}
            />
            {!loading && (
                <Form preserve={false} colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} form={form} onFinish={onSave}>
                    <Form.Item
                        initialValue={entity?.code}
                        validateFirst={true}
                        label={
                            <>
                                ID
                                <Tooltip title='Если поле не заполнено, ID сгенерируется атоматически'>
                                    <InfoCircleOutlined style={{ marginLeft: 5 }} />{' '}
                                </Tooltip>
                            </>
                        }
                        name='code'
                        rules={[
                            {
                                validator: async (_, value: number) => {
                                    const isCodeExist = await serverFetch(`consignees/codeexist/${id}/${value}`, { method: 'GET' })
                                        .then((data) => {
                                            return data;
                                        })
                                        .catch((ex) => {
                                            exception(api, 'Ошибка проверки кода', ex, () => d(userLoaded(undefined)));
                                        });

                                    if (isCodeExist) return Promise.reject('Грузополучатель с указанным кодом уже существует');
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            disabled={isViewOnly || (!!id && !hasPermission(userSession.permissions, Permission.FullAccess))}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, code: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.type}
                        required
                        label='Тип'
                        name='type'
                        rules={[{ required: true, message: 'Укажите тип' }]}
                    >
                        <Select
                            disabled={isViewOnly}
                            onChange={(value) => {
                                setEntity({ ...entity, type: value, userId: undefined });
                            }}
                            options={consigneeTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />
                    </Form.Item>
                    {entity.type === ConsigneeType.GroupMember && (
                        <>
                            <Form.Item
                                initialValue={entity?.userId}
                                required
                                label='Поставщик'
                                name='userId'
                                rules={[{ required: true, message: 'Укажите учетную запись основного поставщика' }]}
                            >
                                <Select
                                    onChange={(value) => {
                                        setEntity({ ...entity, userId: value });
                                    }}
                                    options={users.map((u) => {
                                        return { value: u.id, label: u.login };
                                    })}
                                />
                            </Form.Item>

                            <Form.Item
                                initialValue={entity?.deliveryType}
                                required
                                label='Тип доставки'
                                name='deliveryType'
                                rules={[{ required: true, message: 'Укажите тип доставки' }]}
                            >
                                <Select
                                    onChange={(value) => {
                                        setEntity({ ...entity, deliveryType: value });
                                    }}
                                    options={deliveryTypes.map((t) => {
                                        return { value: t.value, label: t.label };
                                    })}
                                />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item
                        initialValue={entity?.allMarkings}
                        required
                        label={
                            <>
                                Маркировка
                                <Tooltip title="Для разделения маркировок используйте разделитель '/'">
                                    <InfoCircleOutlined style={{ marginLeft: 5 }} />
                                </Tooltip>
                            </>
                        }
                        name='allMarkings'
                        rules={[{ required: true, message: 'Укажите маркировку' }]}
                    >
                        <TextArea
                            autoFocus
                            onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toUpperCase())}
                            disabled={isViewOnly}
                            rows={3}
                            onChange={(data) => {
                                setEntity({ ...entity, allMarkings: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.cityId}
                        required
                        label='Город доставки'
                        name='city'
                        rules={[{ required: true, message: 'Выберите город доставки' }]}
                    >
                        <Select
                            disabled={isViewOnly}
                            showSearch
                            onChange={(value) => setEntity({ ...entity, cityId: value })}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.tag} label='Тег' name='tag'>
                        <AutoComplete
                            allowClear
                            disabled={isViewOnly}
                            showSearch
                            onChange={(value) => setEntity({ ...entity, tag: value })}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={tags.map((c) => {
                                return { value: c.name, label: c.name };
                            })}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.warehouseId} label='Транзитный склад' name='warehouse'>
                        <Select
                            allowClear
                            disabled={isViewOnly}
                            showSearch
                            onChange={(value) => setEntity({ ...entity, warehouseId: value })}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={warehouses.map((w) => {
                                return { value: w.id, label: `${w.code} (${w.cityName})` };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.address}
                        required
                        label='Адрес'
                        name='address'
                        rules={[{ required: true, message: 'Укажите адрес' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            onChange={(data) => {
                                setEntity({ ...entity, address: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.fullName}
                        required
                        label='ФИО'
                        name='fullName'
                        rules={[{ required: true, message: 'Укажите ФИО' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            onChange={(data) => {
                                setEntity({ ...entity, fullName: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <PhoneInputFormItem
                        value={entity?.phone}
                        name='phone'
                        label='Телефон'
                        required={true}
                        disabled={isViewOnly}
                        onChange={(value) => {
                            setEntity({ ...entity, phone: value });
                        }}
                    />
                    <Form.Item label='Скрыть телефон' initialValue={entity?.hidePhone} valuePropName='checked' name='hidePhone'>
                        <Switch
                            disabled={isViewOnly}
                            onChange={(value) => {
                                setEntity({ ...entity, hidePhone: value });
                            }}
                        />
                    </Form.Item>

                    {/*   <Form.Item wrapperCol={{ offset: 1, span: 11 }}>
                        <Divider>Настройка тарифа</Divider>
                    </Form.Item>
                    {entity.tariffSettings && renderSettings()} */}

                    <Form.Item wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {!isViewOnly &&
                                (entity.isArchived ? (
                                    (hasPermission(userSession.permissions, Permission.FullAccess) ||
                                        hasPermission(userSession.permissions, Permission.RestoreConsignee)) && (
                                        <Button type='primary' onClick={() => onRestore()}>
                                            Восстановить
                                        </Button>
                                    )
                                ) : (
                                    <Button type='primary' htmlType='submit' loading={loading}>
                                        Сохранить
                                    </Button>
                                ))}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Consignee;
