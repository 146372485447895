import React, { useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { Modal, Input, Button, Tooltip, Flex } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import { MessageFilled } from '@ant-design/icons';

interface ICommentProps {
    comment: string | undefined;
    editMode?: boolean;
    size?: SizeType;
    onSave?: (e: string | undefined) => void;
}

const Comment = (props: ICommentProps) => {
    const { comment, editMode, size, onSave } = props;

    const { TextArea } = Input;

    const [commentState, setCommentState] = useState<string | undefined>();
    const [openModal, setOpenModal] = useState<boolean>();
    const [fontSize, setFontSize] = useState<number>(18);

    useEffect(() => {
        setCommentState(comment);
    }, [comment]);

    useEffect(() => {
        switch (size) {
            case 'small': {
                setFontSize(12);
                break;
            }
            case 'middle': {
                setFontSize(14);
                break;
            }
            case 'large': {
                setFontSize(18);
                break;
            }
            default: {
                setFontSize(18);
                break;
            }
        }

        setCommentState(comment);
    }, [size]);

    const handleClose = () => {
        setCommentState(undefined);
        setOpenModal(false);
    };

    const getTooltipTitle = (title: string) => {
        const showDetails: boolean = !(title && title.length <= 100) as boolean;

        if (showDetails) title = title.substring(0, 100);

        const array = title.split('\n');

        return (
            <Flex vertical gap='small' align='end'>
                <Flex vertical>
                    {array.map((item) => {
                        return <div key={uuid()}>{item}</div>;
                    })}
                </Flex>
                {showDetails && (
                    <Button style={{ color: 'var(--primary-color)' }} type='link' onClick={() => setOpenModal(true)}>
                        Подробнее...
                    </Button>
                )}
            </Flex>
        );
    };

    return (
        <>
            <Tooltip placement='top' title={comment ? getTooltipTitle(comment) : editMode && 'Добавить комментарий'}>
                <Button
                    style={{ width: 'fit-content', height: 'fit-content' }}
                    className={comment ? 'comment-btn' : 'no-comment-btn'}
                    type='link'
                    icon={<MessageFilled style={{ fontSize: fontSize }} />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenModal(true);
                    }}
                />
            </Tooltip>

            <Modal
                title='Комментарий'
                width={600}
                open={openModal}
                okButtonProps={{
                    style: {
                        display: editMode ? 'inline-flex' : 'none',
                    },
                }}
                onOk={() => {
                    if (editMode && onSave) onSave(commentState);
                    handleClose();
                }}
                cancelText={editMode ? 'Отмена' : 'Закрыть'}
                onCancel={() => {
                    handleClose();
                }}
                maskClosable={false}
            >
                <TextArea
                    readOnly={!editMode}
                    rows={10}
                    defaultValue={commentState}
                    onChange={(e) => {
                        e.stopPropagation();
                        setCommentState(e.target.value);
                    }}
                />
            </Modal>
        </>
    );
};

export default Comment;
