import React, { useState } from 'react';

import { Button, Tooltip, Flex } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import { ButtonType, ButtonColorType, ButtonVariantType } from 'antd/es/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import DropdownControl from '@controls/button/dropdown-control';

import './toolbar.css';

interface IFilterButton {
    active: boolean;
    onHideFilter: () => void;
}

interface IToolbar {
    commands: Array<ICommandProps>;
    farControl?: any;
    farCommands?: Array<any>;
    style?: any;
    filter?: IFilterButton;
}

export interface ICommandProps {
    key: string;
    label: string;
    disabled?: boolean;
    type?: ButtonType;
    color?: ButtonColorType;
    variant?: ButtonVariantType;
    icon?: React.ReactNode;
    onClick?: () => void;
    size?: SizeType;
    showLabel?: boolean;
    style?: any;
    children?: any[];
    hidden?: boolean;
}

const Toolbar = (props: IToolbar) => {
    const { commands, farControl, farCommands, style, filter } = props;

    return (
        <Flex align='center' justify='space-between' className='commands-container' style={style}>
            <Flex gap='small'>
                {commands.map((item: ICommandProps) => {
                    return (
                        !item.hidden && (
                            <Tooltip key={item.key} placement='top' title={item.label}>
                                {item.children ? (
                                    <DropdownControl item={item} />
                                ) : (
                                    <Button
                                        variant={item.variant}
                                        color={item.color}
                                        disabled={item.disabled}
                                        type={item.type}
                                        icon={item.icon}
                                        style={item.style}
                                        onClick={item.onClick}
                                        size={item.size || 'middle'}
                                    >
                                        {item.showLabel && item.label}
                                    </Button>
                                )}
                            </Tooltip>
                        )
                    );
                })}
            </Flex>

            <Flex gap='small'>
                {farControl}
                {farCommands?.map((item: any) => {
                    return (
                        <Tooltip key={item.key} placement='left' title={item.label}>
                            <Button
                                disabled={item.disabled}
                                type={item.type}
                                icon={item.icon}
                                style={item.style}
                                onClick={item.onClick}
                                size='middle'
                            ></Button>
                        </Tooltip>
                    );
                })}
                {filter && (
                    <Tooltip key='filter' placement='left' title='Фильтр'>
                        <Button
                            type={filter.active ? 'primary' : 'default'}
                            icon={<FilterFilled />}
                            onClick={() => {
                                if (filter.onHideFilter) filter.onHideFilter();
                            }}
                            size='middle'
                        ></Button>
                    </Tooltip>
                )}
            </Flex>
        </Flex>
    );
};

export default Toolbar;
