import React, { useState, useEffect, useRef } from 'react';

import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useResizeObserver } from 'usehooks-ts';

import { Input, Table, Select, DatePicker, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception } from '@extensions/notification';

import { delayAction, getDateTimeLocal, getEnumList } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IEmailOutboxFilter } from '@entities/email-outbox-filter';
import { IEmailOutbox } from '@entities/email-outbox';

import { EmailOutboxStatus, enumLabel as emailStatusLabel } from '@enums/email-outbox-status';
import { IEnumItem } from '@enums/enum-item';

dayjs.extend(utc);

const filterContext: string = 'Emails';

const Emails = () => {
    const initFilter: IEmailOutboxFilter = {};

    const { RangePicker } = DatePicker;

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const filter = useAppSelector<IEmailOutboxFilter>((s) => s.filters[filterContext]);

    const [entities, setEntities] = useState<Array<IEmailOutbox>>([]);
    const [statuses] = useState<Array<IEnumItem>>(getEnumList(EmailOutboxStatus, emailStatusLabel));
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setEntities([]);

        setLoading(true);

        serverFetch('notifications/emails', { method: 'GET', queryParams: filter })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения сообщений', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => setRefreshRequired(true),
                    },
                ]}
                filter={{
                    active: showFilter,
                    onHideFilter: () => {
                        setShowFilter(!showFilter);
                    },
                }}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            key='searchText'
                            placeholder='Email/ Получатель / ИНН / Контрагент / Тема /Сообщение'
                            value={filter?.searchText}
                            style={{ width: 320 }}
                            onChange={(data) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                        <RangePicker
                            style={{ width: 260 }}
                            allowEmpty={[true, true]}
                            key='created'
                            format='DD.MM.YYYY'
                            placeholder={['Создано с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[
                                filter?.createdFrom ? dayjs(filter?.createdFrom) : null,
                                filter?.createdTo ? dayjs(filter?.createdTo) : null,
                            ]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                createdFrom: undefined,
                                                createdTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );
                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            createdFrom: value[0]
                                                ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                            createdTo: value[1]
                                                ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                        <Select
                            key='status'
                            placeholder='Статус'
                            value={filter?.statuses}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 200 }}
                            onChange={(value) => d(setFilter({ ...filter, statuses: value }, filterContext))}
                            options={statuses.map((s) => {
                                return { value: s.value, label: s.label };
                            })}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IEmailOutbox> = [
            {
                title: 'Получатель',
                dataIndex: 'recipient',
                width: 200,
            },
            {
                title: 'Email',
                dataIndex: 'to',
                width: 200,
            },
            {
                title: 'Контрагент',
                dataIndex: 'legalName',
                width: 250,
            },
            {
                title: 'ИНН',
                dataIndex: 'inn',
                width: 200,
            },
            {
                title: 'Тема',
                width: 300,
                dataIndex: 'subject',
            },
            {
                title: 'Создано',
                width: 130,
                render: (_, record) => {
                    return getDateTimeLocal(record.createdOn);
                },
            },
            {
                title: 'Статус',
                width: 130,
                render: (_, record) => {
                    return emailStatusLabel(record.status);
                },
            },
            {
                title: 'Отправлено',
                width: 130,
                render: (_, record) => {
                    return getDateTimeLocal(record.sentOn);
                },
            },
            {
                title: 'Отправитель',
                dataIndex: 'createdByName',
                width: 250,
            },

            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                pagination={false}
                virtual={true}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 195px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {contextHolder}
        </div>
    );
};

export default Emails;
