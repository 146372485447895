import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Modal, Select, Table, Button, Input, Popconfirm, Form, InputNumber, Row, Col, Spin, Switch, Empty } from 'antd';
import { LoadingOutlined, DeleteFilled, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { NotificationInstance } from 'antd/es/notification/interface';

import Toolbar from '@controls/toolbar/toolbar';

import Packages from './packages';

import { exception } from '@extensions/notification';
import { getDate, getDateTimeLocal } from '@extensions/utils';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IBox } from '@entities/box';
import { IBoxGroup } from '@entities/box-group';
import { IMarking } from '@entities/marking';
import { ITruck } from '@entities/truck';
import { ITruckFilter } from '@entities/truck-filter';
import { ITransferBox } from '@entities/transfer-box';
import { ICountryItem } from '@entities/country-item';
import { IBillPackage } from '@entities/bill-package';
import { ICountry } from '@entities/country';
import { IDeleteBoxesParams } from '@entities/delete-boxes-params';

import { TruckType } from '@enums/truck-type';
import { UnitType } from '@enums/unit-type';

dayjs.extend(utc);

interface ITransferBoxesModal {
    boxGroupId: string;
    splitConsignment: boolean;
    boxIds?: Array<string>;
    packages?: Array<IBillPackage>;
    items?: Array<ICountryItem>;
    countries?: Array<ICountry>;
    onSave: () => void;
    onCancel: () => void;
    api: NotificationInstance;
}

const TransferBoxesModal = (props: ITransferBoxesModal) => {
    const { boxGroupId, items, packages, boxIds, splitConsignment, countries, onSave, onCancel, api } = props;

    const { TextArea } = Input;

    const [transferForm] = Form.useForm();
    const [deleteBoxForm] = Form.useForm();

    const d = useAppDispatch();

    const [boxes, setBoxes] = useState<Array<IBox>>();
    const [boxGroup, setBoxGroup] = useState<IBoxGroup>();
    const [loading, setLoading] = useState<boolean>(false);
    const [boxesLoading, setBoxesLoading] = useState<boolean>(false);

    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [markings, setMarkings] = useState<Array<IMarking>>([]);

    const [openDeleteBoxModal, setOpenDeleteBoxModal] = useState<boolean>(false);
    const [useBoxSection, setUseBoxSection] = useState<boolean>(false);
    const [boxSectionIsViewOny] = useState<boolean>(!!boxIds);

    const [useBoxes, setUseBoxes] = useState<boolean | undefined>(undefined);
    const [usePackages, setUsePackages] = useState<boolean | undefined>(undefined);
    const [transferPackages, setTransferPackages] = useState<Array<IBillPackage>>();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const truckFilter: ITruckFilter = {
        isArchived: false,
        type: TruckType.Import,
    };

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch(`warehouse/boxgroup/${boxGroupId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маркировок', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('markings', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маркировок', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('trucks/list', { method: 'GET', queryParams: truckFilter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),

                await onLoadBoxes(),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                const entity: IBoxGroup = result[0][0];

                if (entity) {
                    transferForm.setFieldValue('boxGroupId', entity.id);
                    transferForm.setFieldValue('markingId', entity.markingId);
                    transferForm.setFieldValue('countryId', entity.countryId);
                    transferForm.setFieldValue('truckId', entity.truckId);
                    transferForm.setFieldValue('itemId', entity.itemId);
                    transferForm.setFieldValue('awbNumber', entity.awbNumber);

                    setBoxGroup(entity);
                }

                setMarkings(result[0][1]);
                setTrucks(result[0][2]);

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (!!boxes && boxes.length > 0) {
            setUseBoxSection(true);
            setUseBoxes(true);
            return;
        }

        setUseBoxes(false);
    }, [boxes]);

    useEffect(() => {
        if (useBoxes == false && !!packages && packages.length > 0) {
            packages.map((p) => (p.maxBoxQty = p.boxQty));

            setTransferPackages(packages);

            setUsePackages(true);
            setUseBoxSection(true);
            return;
        }

        setUsePackages(false);
    }, [useBoxes]);

    const onLoadBoxes = async () => {
        setBoxesLoading(true);

        serverFetch(`warehouse/boxgroup/${boxGroupId}/boxes`, { method: 'GET' })
            .then((data: Array<IBox>) => {
                let loadedBoxes: Array<IBox> = data;
                if (boxIds != undefined) {
                    loadedBoxes = data.filter((d) => boxIds.includes(d.id));
                }

                setBoxes(loadedBoxes.length > 0 ? loadedBoxes : undefined);
                setBoxesLoading(false);
            })
            .catch((ex) => {
                setBoxesLoading(false);
                exception(api, 'Ошибка получения коробок', ex, () => d(userLoaded(undefined)));
            });
    };

    const onTransfer = (entity: ITransferBox) => {
        entity.boxIds = boxIds != undefined ? boxIds : (selectedRowKeys as Array<string>);
        entity.packages = transferPackages;

        serverFetch(`warehouse/transfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                transferForm.resetFields();
                onSave();
            })
            .catch((ex) => {
                exception(api, 'Ошибка перестановка коробок', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteBoxes = (data: IDeleteBoxesParams) => {
        serverFetch(`warehouse/boxes`, { method: 'DELETE', bodyData: data })
            .then(() => {
                deleteBoxForm.resetFields();
                setOpenDeleteBoxModal(false);

                onLoadBoxes();
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления коробки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const renderBoxesToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Удалить коробки',
                        key: 'delete',
                        disabled: selectedRowKeys.length <= 0,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            deleteBoxForm.setFieldValue('ids', selectedRowKeys);
                            setOpenDeleteBoxModal(true);
                        },
                    },
                ]}
            />
        );
    };

    const renderBoxesTable = () => {
        const columns: ColumnsType<IBox> = [
            {
                title: ' ',
                hidden: boxSectionIsViewOny,
                key: 'boxAction',
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return (
                        <Popconfirm
                            title={`Удалить коробку "${record.number}"?`}
                            onConfirm={() => {
                                deleteBoxForm.setFieldValue('ids', [record.id]);
                                setOpenDeleteBoxModal(true);
                            }}
                        >
                            <Button icon={<DeleteFilled />} size={'small'} />
                        </Popconfirm>
                    );
                },
            },
            {
                title: 'Номер',
                dataIndex: 'number',
                width: 150,
                onCell: (record) => ({
                    style: {
                        color: !record.acceptedOn ? 'var(--main-red)' : '',
                    },
                }),
            },
            {
                title: 'Принято',
                align: 'center',
                width: 120,
                render: (_, record) => {
                    return getDateTimeLocal(record.acceptedOn);
                },
            },
            {
                title: 'Отправлено',
                align: 'center',
                width: 120,
                render: (_, record) => {
                    return getDateTimeLocal(record.shippedOn);
                },
            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                width: 400,
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: boxesLoading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={boxes}
                pagination={false}
                scroll={{ y: 400 }}
                rowSelection={{
                    selectedRowKeys,
                    columnWidth: 32,
                    onChange: onSelectChange,
                    getCheckboxProps: (record) => ({
                        style: { display: boxSectionIsViewOny ? 'none' : '' },
                    }),
                }}
            />
        );
    };

    const renderPackagesTable = () => {
        return (
            <Packages
                dataSource={transferPackages || []}
                onChange={(e) => {
                    setTransferPackages(e);
                }}
            />
        );
    };

    return (
        <>
            <Modal
                title={
                    <>
                        Перестановка коробок
                        {(loading || boxesLoading) && (
                            <Spin
                                tip='Загрузка изображений...'
                                indicator={<LoadingOutlined style={{ fontSize: 18, marginLeft: 10 }} spin />}
                            />
                        )}
                    </>
                }
                open={true}
                onOk={() => {
                    transferForm.submit();
                }}
                onCancel={() => {
                    transferForm.resetFields();
                    onCancel();
                }}
                width={useBoxSection ? 1200 : 500}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Row>
                    <Col span={useBoxSection ? 9 : 24}>
                        <Form
                            disabled={loading}
                            colon={false}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={onTransfer}
                            form={transferForm}
                            style={{ marginTop: 20 }}
                        >
                            <Form.Item name='boxGroupId' hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label='Маркировка' name='markingId' rules={[{ required: true, message: 'Укажите маркировку' }]}>
                                <Select
                                    style={{ width: '100%' }}
                                    autoFocus
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(a, b) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={
                                        markings &&
                                        markings.map((m) => {
                                            return { value: m.id, label: `${m.code} / ${m.cityName}` };
                                        })
                                    }
                                />
                            </Form.Item>
                            {countries ? (
                                <Form.Item label='Страна' name='countryId' style={{ width: '100%' }} required={true}>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                        filterSort={(a, b) =>
                                            (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                        }
                                        options={countries.map((i) => {
                                            return { value: i.id, label: i.name };
                                        })}
                                    ></Select>
                                </Form.Item>
                            ) : (
                                <Form.Item name='countryId' hidden>
                                    <Input />
                                </Form.Item>
                            )}
                            {items ? (
                                <Form.Item label='Продукт' name='itemId' style={{ width: '100%' }} required={true}>
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                        filterSort={(a, b) =>
                                            (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                        }
                                        options={items.map((i) => {
                                            return { value: i.itemId, label: i.itemName };
                                        })}
                                    ></Select>
                                </Form.Item>
                            ) : (
                                <Form.Item name='itemId' hidden>
                                    <Input />
                                </Form.Item>
                            )}
                            {!useBoxSection && (
                                <Form.Item
                                    name='qty'
                                    label={
                                        boxGroup?.unitType == UnitType.Cart
                                            ? 'Телега'
                                            : boxGroup?.unitType == UnitType.Pallet && (!packages || packages.length <= 0)
                                            ? 'Паллета'
                                            : 'Коробки'
                                    }
                                    rules={[
                                        { required: true, message: 'Укажите количество' },
                                        {
                                            validator: async (_, value) => {
                                                if (value != null && value <= 0) return Promise.reject('Укажите количество');
                                                if (value > (boxGroup?.qty ?? 0))
                                                    return Promise.reject('Количество не может превышать размер поставки');

                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        addonAfter={<span style={{ fontWeight: 600, color: '#228B22' }}>из {boxGroup?.qty} шт</span>}
                                        min={0}
                                        step='1'
                                    />
                                </Form.Item>
                            )}
                            <Form.Item label='Номер AWB' name='awbNumber'>
                                <Input />
                            </Form.Item>
                            <Form.Item label='Машина' name='truckId'>
                                <Select
                                    style={{ width: '100%' }}
                                    autoFocus
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    filterSort={(a, b) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={trucks.map((t) => {
                                        return { value: t.id, label: `${t.number} - ${getDate(t.departureOn)}` };
                                    })}
                                />
                            </Form.Item>
                            <Form.Item label='Вес Брутто' name='grossWeight'>
                                <InputNumber
                                    addonAfter={
                                        <span style={{ fontWeight: 600, color: '#228B22' }}>из {boxGroup?.volumeWeight ?? 0} кг</span>
                                    }
                                    min={0}
                                    decimalSeparator=','
                                    stringMode
                                    style={{ width: 200 }}
                                />
                            </Form.Item>
                            <Form.Item label='Объемный вес' name='volumeWeight'>
                                <InputNumber
                                    addonAfter={
                                        <span style={{ fontWeight: 600, color: '#228B22' }}>из {boxGroup?.volumeWeight ?? 0} кг</span>
                                    }
                                    min={0}
                                    decimalSeparator=','
                                    stringMode
                                    style={{ width: 200 }}
                                />
                            </Form.Item>
                            {splitConsignment && (
                                <Form.Item label='Разделить поставку' name='splitConsignmentRequired' valuePropName='checked'>
                                    <Switch />
                                </Form.Item>
                            )}
                        </Form>
                    </Col>
                    {useBoxSection && (
                        <Col offset={1} span={14}>
                            <div style={{ marginTop: 20 }}>
                                {useBoxes ? (
                                    <>
                                        {renderBoxesToolbar()}
                                        {renderBoxesTable()}
                                    </>
                                ) : usePackages ? (
                                    renderPackagesTable()
                                ) : (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Груз не найден....' />
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            </Modal>

            <Modal
                width={400}
                title='Причина удаление коробки'
                open={openDeleteBoxModal}
                okText='ОК'
                closable={false}
                onOk={() => {
                    deleteBoxForm.submit();
                }}
                onCancel={() => {
                    deleteBoxForm.resetFields();
                    setOpenDeleteBoxModal(false);
                }}
            >
                <Form colon={false} wrapperCol={{ span: 24 }} form={deleteBoxForm} onFinish={onDeleteBoxes}>
                    <Form.Item name='ids' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='reason' required rules={[{ required: true, message: 'Укажите причину удаления' }]}>
                        <TextArea rows={6} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default TransferBoxesModal;
