import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Table, Tag, notification } from 'antd';

import { LoadingOutlined, ReloadOutlined, PrinterOutlined } from '@ant-design/icons';

import printJS from 'print-js';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';
import PrintProcessing from '@controls/print-processing';

import { dataURItoBlob } from '@extensions/utils';

import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IConsignment } from '@entities/consignment';
import { ICountry } from '@entities/country';
import { IBoxGroup } from '@entities/box-group';
import { ColumnsType } from 'antd/es/table';
import { ITruckFilter } from '@entities/truck-filter';

import { TruckType } from '@enums/truck-type';

interface IConsignmentHeader {
    cityKey: string;
    cityName: string;
    consignments: Array<IConsignment>;
    tag: string;
    totalVolume: number;
    totalQty: number;
}

const MergeOrders = () => {
    const { ids } = useParams();

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const [truckNumbers, setTruckNumbers] = useState<Array<string>>([]);
    const [headers, setHeaders] = useState<Array<IConsignmentHeader>>([]);
    const [countries, setCountries] = useState<Array<ICountry>>([]);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [startPrint, setStartPrint] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setCountries([]);
        setTruckNumbers([]);
        setHeaders([]);

        const fetchData = async () => {
            setLoading(true);

            const filter: ITruckFilter = {
                ids: ids ? JSON.parse(ids) : undefined,
                type: TruckType.Import,
                isArchived: false,
            };

            const promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('trucks/merge', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0].filter((c: ICountry) => c.generateTariff));

                const data = result[0][1] || [];
                setTruckNumbers(data.truckNumbers);
                setHeaders(data.consignments);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Печать',
                        key: 'print',
                        disabled: loading || startPrint,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            setStartPrint(true);

                            serverFetch(`trucks/print`, { method: 'POST', bodyData: ids ? JSON.parse(ids) : undefined })
                                .then((data) => {
                                    const fileBody = `data:${data.contentType};base64,${data.fileContents}`;

                                    const blobURL = URL.createObjectURL(dataURItoBlob(fileBody));
                                    printJS(blobURL);

                                    setStartPrint(false);
                                })
                                .catch((ex) => {
                                    setStartPrint(false);
                                    exception(api, 'Ошибка выгрузки листа приемки', ex, () => d(userLoaded(undefined)));
                                });
                        },
                    },
                ]}
            />
        );
    };

    const tableLoading = {
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
    };

    const expandedConsignments = (record: IConsignmentHeader) => {
        const consignments = record.consignments?.map((c: IConsignment) => {
            const boxGroups: any = [];
            let totalQty = 0;
            let totalVolume = 0;

            c.boxGroups?.map((b: IBoxGroup) => {
                if (b.countryId) boxGroups[b.countryId] = { qty: b.qty };

                totalQty += b.qty || 0;
                totalVolume += b.calcVolume || 0;
            });

            const nsignment: IConsignment = {
                ...c,
                ...boxGroups,
                totalQty: totalQty,
                totalVolume: totalVolume,
            };
            return nsignment;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                align: 'center',
                width: 80,
            },

            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 200,
            },
        ];

        countries.map((c) => {
            columns.push({
                title: c.name,
                width: 120,
                align: 'center',
                render: (_: any, record: any) => {
                    const groupBox = c.id && record[c.id];
                    return (
                        groupBox && (
                            <>
                                <span>{groupBox.qty}</span>
                            </>
                        )
                    );
                },
            });
        });

        columns.push({
            align: 'center',
            width: 120,
            dataIndex: 'totalQty',
            onCell: (record) => ({
                style: {
                    background: '#fafafa',
                    fontWeight: 600,
                },
            }),
        });

        columns.push({
            align: 'center',
            width: 120,
            onCell: (record) => ({
                style: {
                    background: '#fafafa',
                    fontWeight: 600,
                },
            }),
            render: (_, record: IConsignment) => {
                return record.totalVolume?.toFixed(2);
            },
        });

        columns.push({});

        return <Table rowKey='id' size='small' columns={columns} dataSource={consignments} pagination={false} />;
    };
    const renderTable = () => {
        const columns: ColumnsType<IConsignmentHeader> = [
            {
                title: 'Город доставки',
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                width: 1100,
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.cityName}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                dataIndex: 'totalQty',
                width: 120,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                width: 120,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return record.totalVolume?.toFixed(2);
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                loading={tableLoading}
                columns={columns}
                dataSource={headers}
                pagination={false}
                expandable={{
                    columnWidth: 32,
                    expandedRowRender: expandedConsignments,
                }}
                scroll={{ y: `calc(100vh - 245px)` }}
            />
        );
    };

    return (
        <>
            <FormHeader title={`Объединение машин: ${truckNumbers.join(', ')}`} />
            {renderToolbar()}
            {renderTable()}

            {startPrint && <PrintProcessing />}
            {contextHolder}
        </>
    );
};

export default MergeOrders;
