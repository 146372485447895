import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { notification } from 'antd';

import BalanceDetailsControl from '@controls/balance-details/balance-details';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { exception } from '@extensions/notification';

import { serverFetch } from '@src/core/server';

import { IUser } from '@entities/user';
import { IUserSession } from '@entities/user-session';

import { Permission, hasPermission } from '@enums/permission';

const BalanceDetails = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { userId } = useParams();

    const d = useAppDispatch();

    const [user, setUser] = useState<IUser>();

    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        serverFetch(`users/${userId}`, { method: 'GET' })
            .then((data) => {
                setUser(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
            });
    }, []);

    return (
        <>
            {user && (
                <BalanceDetailsControl
                    userId={userId || ''}
                    isViewMode={!hasPermission(userSession.permissions, Permission.ManageBalance)}
                    showExchangeRateInfo={true}
                    useTagRequired={!!user.tag}
                    tag={user.tag}
                />
            )}
            {contextHolder}
        </>
    );
};

export default BalanceDetails;
