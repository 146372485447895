import React from 'react';

import { Divider, Col, Row, Input, Form, Space, Button, DatePicker, Tooltip, Select, InputNumber, Flex } from 'antd';
import { RollbackOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { HookAPI } from 'antd/es/modal/useModal';
import { NotificationInstance } from 'antd/es/notification/interface';

import CargoCarrierControl from '@components/logistics/cargo-carrier-control';

import Trackpoints from './trackpoints';

import { useAppSelector } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { ITruck } from '@entities/truck';
import { IWaypointHeader } from '@entities/waypoint-header';
import { ICargoCarrier } from '@entities/cargo-carrier';

import { TruckStatus, enumLabel } from '@enums/truck-status';
import { Permission, hasPermission } from '@enums/permission';
import { Currency, enumSign as currencySign } from '@src/core/enums/currency';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ITruckWeb {
    truck: ITruck;
    canManageTruck: boolean;
    modal: HookAPI;
    headers: Array<IWaypointHeader>;
    cargoCarriers: Array<ICargoCarrier>;
    loading: boolean;
    api: NotificationInstance;
    onFinish: () => void;
    onChange: (e: ITruck) => void;
    onRevertOnway: () => void;
    onClose: () => void;
    loadCargoCarriers: () => void;
}

const TruckWeb = (props: ITruckWeb) => {
    const { TextArea } = Input;

    const {
        truck,
        canManageTruck,
        modal,
        headers,
        cargoCarriers,
        loading,
        api,
        onChange,
        onFinish,
        onRevertOnway,
        onClose,
        loadCargoCarriers,
    } = props;

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    return (
        <Row>
            <Col span={10}>
                <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
                    <Form.Item
                        initialValue={truck.number}
                        required={true}
                        label='Номер'
                        name='number'
                        rules={[{ required: true, message: 'Укажите номер машины' }]}
                    >
                        <Input
                            disabled={!canManageTruck}
                            autoFocus
                            onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
                                onChange({ ...truck, number: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        required={true}
                        label='Маршрут'
                        name='waypointHeaderId'
                        initialValue={truck.waypointHeaderId}
                        rules={[{ required: true, message: 'Укажите маршрут' }]}
                    >
                        <Select
                            disabled={truck.status == TruckStatus.Finished || truck.status == TruckStatus.Cancelled}
                            onChange={(value) => {
                                if (truck.status != TruckStatus.New) {
                                    modal.confirm({
                                        title: `Маршурт будет перестроить, продолжить?`,
                                        icon: <QuestionCircleOutlined />,
                                        okType: 'primary',
                                        okText: 'ОК',
                                        cancelText: 'Отмена',
                                        onOk: () => onChange({ ...truck, waypointHeaderId: value }),
                                    });
                                } else {
                                    onChange({ ...truck, waypointHeaderId: value });
                                }
                            }}
                            options={headers.map((h) => {
                                return { value: h.id, label: h.name };
                            })}
                        />
                    </Form.Item>

                    {truck.id && (
                        <Form.Item label='Статус'>
                            <Space.Compact style={{ width: '100%' }}>
                                <Input disabled={true} defaultValue={enumLabel(truck.status)} />
                                {hasPermission(userSession.permissions, Permission.FullAccess) && truck.status === TruckStatus.Finished && (
                                    <Tooltip title='Вернуть статус "В Пути"'>
                                        <Button
                                            type='primary'
                                            icon={<RollbackOutlined />}
                                            onClick={() => {
                                                modal.confirm({
                                                    title: `Изменить статус машины на "В Пути"?`,
                                                    okType: 'primary',
                                                    icon: <QuestionCircleOutlined />,
                                                    okText: 'ОК',
                                                    cancelText: 'Отмена',
                                                    onOk: () => {
                                                        onRevertOnway();
                                                    },
                                                });
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Space.Compact>
                        </Form.Item>
                    )}

                    {truck.id && (
                        <>
                            <Form.Item
                                label='Дата отгрузки'
                                name='departureOn'
                                initialValue={
                                    truck.departureOn && dayjs(truck.departureOn).utc(true).set('hour', 0).set('minute', 0).set('second', 0)
                                }
                            >
                                <DatePicker
                                    disabled={!canManageTruck}
                                    format={'DD.MM.YYYY'}
                                    onChange={(data) => {
                                        onChange({
                                            ...truck,
                                            departureOn: dayjs(data).utc(true).set('hour', 0).set('minute', 0).set('second', 0),
                                        });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label='Брутто' name='grossWeight' initialValue={truck.grossWeight}>
                                <InputNumber
                                    decimalSeparator=','
                                    min={0}
                                    disabled={!canManageTruck}
                                    stringMode
                                    addonAfter='кг'
                                    onChange={(value: number | null) => {
                                        onChange({ ...truck, grossWeight: value });
                                    }}
                                />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item initialValue={truck.trackingUrl} label='Трекинг URL' name='trackingUrl'>
                        <Input
                            disabled={!canManageTruck}
                            onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
                                if (!truck) return;

                                onChange({ ...truck, trackingUrl: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Комментарий' name='comment' initialValue={truck.comment}>
                        <TextArea
                            disabled={!canManageTruck && !hasPermission(userSession.permissions, Permission.SetCommentTruck)}
                            rows={4}
                            onChange={(data) => {
                                onChange({ ...truck, comment: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item label='Перевозчик'>
                        <CargoCarrierControl
                            baseCargoCarriers={cargoCarriers || []}
                            defaultCargoCarrierName={truck.cargoCarrierName}
                            canManageTruck={canManageTruck}
                            api={api}
                            onSelect={(value: string | undefined) => {
                                onChange({ ...truck, cargoCarrierName: value });
                            }}
                            loadCargoCarriers={loadCargoCarriers}
                        />
                    </Form.Item>

                    {hasPermission(userSession.permissions, Permission.ManageCostPriceTruck) && (
                        <>
                            <Form.Item label='Фрахт' style={{ marginBottom: 0 }}>
                                <Flex gap='small'>
                                    <Form.Item name='charteringAmount' initialValue={truck.charteringAmount}>
                                        <InputNumber
                                            precision={2}
                                            decimalSeparator=','
                                            min={0}
                                            onChange={(value: number | null) => {
                                                onChange({ ...truck, charteringAmount: value });
                                            }}
                                            addonAfter={currencySign(Currency.Eur)}
                                        />
                                    </Form.Item>
                                    <Form.Item name='charteringCrossRate' initialValue={truck.charteringCrossRate}>
                                        <InputNumber
                                            placeholder='Кросс-курс'
                                            precision={4}
                                            decimalSeparator=','
                                            min={0}
                                            onChange={(value: number | null) => {
                                                onChange({ ...truck, charteringCrossRate: value });
                                            }}
                                            addonAfter={currencySign(Currency.Usd)}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <InputNumber
                                            value={(truck.charteringAmount ?? 0) * (truck.charteringCrossRate ?? 0)}
                                            placeholder='Итого'
                                            precision={2}
                                            decimalSeparator=','
                                            disabled={true}
                                            addonAfter={currencySign(Currency.Usd)}
                                        />
                                    </Form.Item>
                                </Flex>
                            </Form.Item>

                            <Form.Item label='Таможня' name='customsAmountUsd' initialValue={truck.customsAmountUsd}>
                                <InputNumber
                                    precision={2}
                                    decimalSeparator=','
                                    min={0}
                                    onChange={(value: number | null) => {
                                        onChange({ ...truck, customsAmountUsd: value });
                                    }}
                                    addonAfter={currencySign(Currency.Usd)}
                                />
                            </Form.Item>

                            <Form.Item label='Коммерция' name='commerceAmountUsd' initialValue={truck.commerceAmountUsd}>
                                <InputNumber
                                    precision={2}
                                    decimalSeparator=','
                                    min={0}
                                    onChange={(value: number | null) => {
                                        onChange({ ...truck, commerceAmountUsd: value });
                                    }}
                                    addonAfter={currencySign(Currency.Usd)}
                                />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item wrapperCol={{ offset: 8, span: 16, xs: 16 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            {
                                <Button type='text' onClick={() => onClose()}>
                                    Закрыть
                                </Button>
                            }

                            {(canManageTruck || hasPermission(userSession.permissions, Permission.SetCommentTruck)) && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            </Col>

            <Col offset={2} span={12}>
                {truck.id && truck.status !== TruckStatus.New && (
                    <>
                        <Divider style={{ marginTop: 0 }} orientation='left'>
                            Трекинг
                        </Divider>
                        <Trackpoints truck={truck} viewOnly={!canManageTruck || truck.status === TruckStatus.Finished} />
                    </>
                )}
            </Col>
        </Row>
    );
};

export default TruckWeb;
