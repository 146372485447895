import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { isMobile } from 'react-device-detect';

import { Row, Modal, notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import TruckWeb from './truck-web';
import TruckMobile from './truck-mobile';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ITruck } from '@entities/truck';
import { IWaypointHeader } from '@entities/waypoint-header';
import { ICargoCarrier } from '@src/core/entities/cargo-carrier';

import { TruckStatus } from '@enums/truck-status';
import { TruckType } from '@enums/truck-type';
import { Permission, hasPermission } from '@enums/permission';
import { Currency } from '@enums/currency';

dayjs.extend(utc);

const Truck = () => {
    const navigate = useNavigate();
    const [api, notificationContextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { id } = useParams();

    const [modal, modalContextHolder] = Modal.useModal();

    const [entity, setEntity] = useState<ITruck>({
        id: undefined,
        number: undefined,
        departureOn: undefined,
        type: TruckType.Import,
        status: TruckStatus.New,
        charteringCurrency: Currency.Eur,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [canManageTruck] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageTruck));

    const [headers, setHeaders] = useState<Array<IWaypointHeader>>([]);
    const [cargoCarriers, setCargoCarriers] = useState<Array<ICargoCarrier>>([]);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch(`trucks/${id}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`waypointheaders`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маршрутов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`cargocarriers/truck`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения перевозчиков', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                if (id) {
                    setEntity(result[0][0]);
                }

                setHeaders(result[0][1]);

                setCargoCarriers(result[0][2]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    const onFinish = () => {
        setLoading(true);

        serverFetch(`trucks`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения машины', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRevertOnway = () => {
        serverFetch(`warehouse/${id}/revertacceptance`, { method: 'POST' })
            .then(() => {
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка изменения статуса', ex, () => d(userLoaded(undefined)));
            });
    };

    const loadCargoCarriers = () => {
        serverFetch(`cargocarriers/truck`, { method: 'GET' })
            .then((data) => {
                setCargoCarriers(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения перевозчиков', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <Row>
                <FormHeader title={`${id ? (canManageTruck ? 'Информация о машине' : 'Управление машиной') : 'Добавить машину'}`} />
            </Row>

            {!loading &&
                (isMobile ? (
                    <TruckMobile
                        truck={entity}
                        onFinish={onFinish}
                        onChange={(e: ITruck) => {
                            setEntity(e);
                        }}
                        onRevertOnway={onRevertOnway}
                        onClose={() => navigate(-1)}
                        canManageTruck={canManageTruck}
                        modal={modal}
                        headers={headers}
                        loading={loading}
                    />
                ) : (
                    <TruckWeb
                        truck={entity}
                        canManageTruck={canManageTruck}
                        modal={modal}
                        headers={headers}
                        loading={loading}
                        api={api}
                        cargoCarriers={cargoCarriers}
                        loadCargoCarriers={loadCargoCarriers}
                        onFinish={onFinish}
                        onChange={(e: ITruck) => setEntity(e)}
                        onRevertOnway={onRevertOnway}
                        onClose={() => navigate(-1)}
                    />
                ))}

            {notificationContextHolder}
            {modalContextHolder}
        </>
    );
};

export default Truck;
