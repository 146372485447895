import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Input, Form, Row, Col, Space, Button, Tooltip, Modal, Flex, InputNumber, Select, Upload, Divider, notification } from 'antd';
import type { UploadProps } from 'antd/es/upload';
import { RollbackOutlined, QuestionCircleOutlined, TruckFilled, PaperClipOutlined } from '@ant-design/icons';

import CargoCarrierControl from '@components/logistics/cargo-carrier-control';

import PhoneInputFormItem from '@controls/phone-input-form-item/phone-input-form-item';
import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';
import { getEnumList } from '@extensions/utils';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { ITruck } from '@entities/truck';
import { IUserSession } from '@entities/user-session';
import { ICargoCarrier } from '@entities/cargo-carrier';

import { TruckType } from '@enums/truck-type';
import { TruckStatus, enumLabel } from '@enums/truck-status';
import { Permission, hasPermission } from '@enums/permission';
import { Currency, enumSign as currencySign, enumLabel as currencyLabel } from '@enums/currency';
import { IEnumItem } from '@enums/enum-item';

dayjs.extend(utc);

const Truck = () => {
    const Buffer = require('buffer/').Buffer;

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { journalId, truckId } = useParams();
    const [api, contextHolder] = notification.useNotification();

    const { TextArea } = Input;

    const d = useAppDispatch();
    const navigate = useNavigate();

    const [modal, modalContextHolder] = Modal.useModal();
    const [entity, setEntity] = useState<ITruck>();
    const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [currencies] = useState<Array<IEnumItem>>(getEnumList(Currency, currencyLabel));
    const [cargoCarriers, setCargoCarriers] = useState<Array<ICargoCarrier>>([]);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch(`cargocarriers/truck`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения перевозчиков', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (truckId) {
                promises.push(
                    await serverFetch(`trucks/${truckId}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCargoCarriers(result[0][0]);

                if (truckId) {
                    setEntity(result[0][1]);
                } else {
                    setEntity({
                        id: undefined,
                        number: undefined,
                        departureOn: undefined,
                        type: TruckType.Delivery,
                        status: TruckStatus.New,
                        charteringCurrency: Currency.Rub,
                    });
                }

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        setIsViewOnly(!journalId || entity?.status === TruckStatus.Finished);
    }, [entity]);

    const loadCargoCarriers = () => {
        serverFetch(`cargocarriers/truck`, { method: 'GET' })
            .then((data) => {
                setCargoCarriers(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения перевозчиков', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRevertOnway = () => {
        if (!truckId) return;

        serverFetch(`trucks/delivery/${truckId}/revertonway`, { method: 'POST' })
            .then(() => {
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка изменения статуса', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCompleteShipment = () => {
        setLoading(true);

        serverFetch(`warehouse/completeshipment/${journalId}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка отправки машины', ex, () => d(userLoaded(undefined)));
            });
    };

    const onFinish = () => {
        if (!entity) return;

        setLoading(true);

        const result = { ...entity, journalId: journalId };

        serverFetch(`trucks`, { method: truckId ? 'PUT' : 'POST', bodyData: result })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения машины', ex, () => d(userLoaded(undefined)));
            });
    };

    const onUpload = async (file: Blob): Promise<string> => {
        const formData = new FormData();
        formData.append('file', file);

        return serverFetch(`trucks/${truckId}/documents/upload`, { method: 'POST', body: formData })
            .then((data) => {
                return data;
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка загрузки файла', ex, () => d(userLoaded(undefined)));
                return undefined;
            });
    };

    const onRemove = (id: string): Promise<string> => {
        return serverFetch(`trucks/documents/file/${id}/delete`, { method: 'DELETE' })
            .then((data) => {
                return data;
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка удаления файла', ex, () => d(userLoaded(undefined)));
                return undefined;
            });
    };

    const handleChange: UploadProps['onChange'] = async ({ file, fileList, event }) => {
        if (!file) return;

        if (file.status == 'uploading' && event === undefined) {
            const result = await onUpload(file.originFileObj as Blob);

            if (result) {
                file.status = 'done';
                file.uid = result;
            }
        } else if (file.status == 'removed') {
            const result = await onRemove(file.uid);
            if (result) {
                file.status = 'done';
            }
        }

        if (entity) setEntity({ ...entity, documents: fileList });
    };

    const handlePreview = async (file: any) => {
        if (file) {
            if (!file.url && !file.preview) {
                let fileObj = file.originFileObj;

                if (typeof fileObj !== 'object') {
                    const buffer = new Buffer.from(file.originFileObj, 'base64');
                    fileObj = new Blob([buffer], { type: 'application/pdf' });
                }

                const fileURL = URL.createObjectURL(fileObj);
                window.open(fileURL);
            }
        }
    };

    return (
        <>
            <Row>
                <FormHeader title={`${truckId ? 'Управление машиной' : 'Добавить машину'}`} />
            </Row>

            {entity && (
                <Row>
                    <Col span={10}>
                        <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
                            <Form.Item
                                initialValue={entity?.number}
                                required={true}
                                label='Номер машины'
                                name='number'
                                rules={[
                                    { required: true, message: 'Укажите номер машины' },
                                    {
                                        validator: async (_, value: string) => {
                                            if (value && value.length < 3) return Promise.reject('Укажите правильный номер автомобиля');
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                wrapperCol={{ span: 5 }}
                            >
                                <Input
                                    disabled={isViewOnly}
                                    autoFocus
                                    min={3}
                                    onChange={(data) => {
                                        if (!entity) return;

                                        setEntity({ ...entity, number: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label='Статус' wrapperCol={{ span: 8 }}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Input disabled={true} defaultValue={enumLabel(entity?.status)} />
                                    {hasPermission(userSession.permissions, Permission.ManageDeliveryStatus) &&
                                        entity.status === TruckStatus.Finished && (
                                            <Tooltip title='Вернуть статус "В Пути"'>
                                                <Button
                                                    type='primary'
                                                    icon={<RollbackOutlined />}
                                                    onClick={() => {
                                                        modal.confirm({
                                                            title: `Изменить статус машины на "В Пути"?`,
                                                            okType: 'primary',
                                                            icon: <QuestionCircleOutlined />,
                                                            okText: 'ОК',
                                                            cancelText: 'Отмена',
                                                            onOk: () => {
                                                                onRevertOnway();
                                                            },
                                                        });
                                                    }}
                                                />
                                            </Tooltip>
                                        )}

                                    {hasPermission(userSession.permissions, Permission.ManageDeliveryStatus) &&
                                        entity.status === TruckStatus.New && (
                                            <Tooltip title='Отправить машину'>
                                                <Button
                                                    type='primary'
                                                    icon={<TruckFilled />}
                                                    onClick={() => {
                                                        modal.confirm({
                                                            title: `Изменить статус машины на "В Пути"?`,
                                                            okType: 'primary',
                                                            icon: <QuestionCircleOutlined />,
                                                            okText: 'ОК',
                                                            cancelText: 'Отмена',
                                                            onOk: () => {
                                                                onCompleteShipment();
                                                            },
                                                        });
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                </Space.Compact>
                            </Form.Item>

                            <Form.Item label='Перевозчик'>
                                <CargoCarrierControl
                                    baseCargoCarriers={cargoCarriers || []}
                                    defaultCargoCarrierName={entity.cargoCarrierName}
                                    canManageTruck={!isViewOnly}
                                    api={api}
                                    onSelect={(value: string | undefined) => {
                                        setEntity({ ...entity, cargoCarrierName: value });
                                    }}
                                    loadCargoCarriers={loadCargoCarriers}
                                />
                            </Form.Item>

                            <PhoneInputFormItem
                                value={entity?.driverPhone}
                                name='driverPhone'
                                label='Телефон водителя'
                                required={true}
                                disabled={isViewOnly}
                                onChange={(value) => {
                                    setEntity({ ...entity, driverPhone: value });
                                }}
                            />

                            <Form.Item initialValue={entity?.trackingUrl} label='Трекинг URL' name='trackingUrl'>
                                <Input
                                    disabled={isViewOnly}
                                    onChange={(data) => {
                                        if (!entity) return;

                                        setEntity({ ...entity, trackingUrl: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label='Комментарий' name='comment' initialValue={entity?.comment}>
                                <TextArea
                                    disabled={isViewOnly}
                                    rows={4}
                                    onChange={(data) => {
                                        setEntity({ ...entity, comment: data.target.value });
                                    }}
                                />
                            </Form.Item>

                            {hasPermission(userSession.permissions, Permission.ManageCostPriceTruck) && (
                                <>
                                    <Form.Item label='Доставка' style={{ marginBottom: 0 }}>
                                        <Flex gap='small'>
                                            <Form.Item name='charteringAmount' initialValue={entity.charteringAmount}>
                                                <InputNumber
                                                    precision={2}
                                                    decimalSeparator=','
                                                    min={0}
                                                    onChange={(value: number | null) => {
                                                        setEntity({ ...entity, charteringAmount: value });
                                                    }}
                                                    addonAfter={
                                                        <Select
                                                            defaultValue={entity.charteringCurrency}
                                                            onChange={(value: number) => {
                                                                setEntity({ ...entity, charteringCurrency: value });
                                                            }}
                                                            options={currencies.map((c) => {
                                                                return {
                                                                    value: c.value,
                                                                    label: c.label,
                                                                };
                                                            })}
                                                        />
                                                    }
                                                />
                                            </Form.Item>
                                            <Form.Item name='charteringCrossRate' initialValue={entity.charteringCrossRate}>
                                                <InputNumber
                                                    placeholder='Кросс-курс'
                                                    precision={4}
                                                    decimalSeparator=','
                                                    min={0}
                                                    onChange={(value: number | null) => {
                                                        setEntity({ ...entity, charteringCrossRate: value });
                                                    }}
                                                    addonAfter={currencySign(Currency.Usd)}
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <InputNumber
                                                    value={(entity.charteringAmount ?? 0) * (entity.charteringCrossRate ?? 0)}
                                                    placeholder='Итого'
                                                    precision={2}
                                                    decimalSeparator=','
                                                    disabled={true}
                                                    addonAfter={currencySign(Currency.Usd)}
                                                />
                                            </Form.Item>
                                        </Flex>
                                    </Form.Item>
                                </>
                            )}

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Space size={'small'} style={{ float: 'right' }}>
                                    <Button type='text' onClick={() => navigate(-1)}>
                                        Закрыть
                                    </Button>
                                    {!isViewOnly && (
                                        <Button type='primary' htmlType='submit' loading={loading}>
                                            Сохранить
                                        </Button>
                                    )}
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={8} offset={1}>
                        <Divider orientation='left'>Документы</Divider>
                        <Form.Item
                            wrapperCol={{ span: 24 }}
                            style={{ textAlign: 'left', marginTop: 10, border: '1px solid #d9d9d9', borderRadius: 5, padding: 5 }}
                        >
                            <Upload
                                onChange={(value) => {
                                    value.file.response = entity.id;
                                    if (handleChange) handleChange(value);
                                }}
                                onPreview={handlePreview}
                                listType='text'
                                multiple
                                fileList={entity.documents}
                                accept='.pdf'
                            >
                                <Tooltip title='Прикрепить документы'>
                                    <Button size='small' type='primary' icon={<PaperClipOutlined />}></Button>
                                </Tooltip>
                                {entity?.documents && entity.documents?.length <= 0 && (
                                    <span style={{ marginLeft: 10 }}>Прикрепить документы...</span>
                                )}
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
            )}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Truck;
