import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useResizeObserver } from 'usehooks-ts';

import { Input, Table, Form, Switch, DatePicker, notification, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, EyeFilled } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception } from '@extensions/notification';

import { delayAction, getDateTimeLocal } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IPushNotification } from '@src/core/entities/push-notification';
import { IPushNotificationFilter } from '@src/core/entities/push-notification-filter';

dayjs.extend(utc);

const filterContext: string = 'Notifications';

const PushNotifications = () => {
    const initFilter: IPushNotificationFilter = { personalOnly: false, unreadOnly: true };

    const { RangePicker } = DatePicker;

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IPushNotificationFilter>((s) => s.filters[filterContext]);

    const [entities, setEntities] = useState<Array<IPushNotification>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setEntities([]);

        setLoading(true);

        serverFetch('notifications', { method: 'GET', queryParams: filter })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения сообщений', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => setRefreshRequired(true),
                    },
                ]}
                filter={{
                    active: showFilter,
                    onHideFilter: () => {
                        setShowFilter(!showFilter);
                    },
                }}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            key='searchText'
                            placeholder='Получатель / ИНН / Контрагент / Сообщение'
                            value={filter?.searchText}
                            style={{ width: 320 }}
                            onChange={(data) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                        <RangePicker
                            style={{ width: 260 }}
                            allowEmpty={[true, true]}
                            key='created'
                            format='DD.MM.YYYY'
                            placeholder={['Отправлено с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[
                                filter?.createdFrom ? dayjs(filter?.createdFrom) : null,
                                filter?.createdTo ? dayjs(filter?.createdTo) : null,
                            ]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                createdFrom: undefined,
                                                createdTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );
                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            createdFrom: value[0]
                                                ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                            createdTo: value[1]
                                                ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                        <Form.Item key='unreadOnly' label='Непрочитанные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.unreadOnly}
                                onChange={(value) => {
                                    d(setFilter({ ...filter, unreadOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IPushNotification> = [
            {
                title: '',
                align: 'center',
                width: 35,
                render: (_, record) => {
                    return (
                        record.isRead && (
                            <Tooltip title='Прочитано'>
                                <EyeFilled style={{ color: 'var(--primary-color)' }} />
                            </Tooltip>
                        )
                    );
                },
            },
            {
                title: 'Получатель',
                dataIndex: 'recipient',
                width: 200,
            },
            {
                title: 'Контрагент',
                dataIndex: 'legalName',
                width: 250,
            },
            {
                title: 'ИНН',
                dataIndex: 'inn',
                width: 200,
            },
            {
                title: 'Сообщение',
                width: 500,
                render: (_: any, record: IPushNotification) => {
                    const dots = record.body && record.body.length > 143 ? '...' : '';
                    return `${record.body?.substring(0, 143)}${dots}`;
                },
            },
            {
                title: 'Отправлено',
                width: 130,
                render: (_: any, record: IPushNotification) => {
                    return getDateTimeLocal(record.createdOn);
                },
            },
            {
                title: 'Отправитель',
                dataIndex: 'createdByName',
                width: 250,
            },

            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        style: { fontWeight: record.isRead ? '' : 600 },
                    };
                }}
                pagination={false}
                virtual={true}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 195px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {contextHolder}
        </div>
    );
};

export default PushNotifications;
