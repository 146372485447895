import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useResizeObserver } from 'usehooks-ts';

import { Input, Select, Table, Tooltip, Modal, Switch, Form, Tag, Flex, notification, InputNumber } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    PrinterOutlined,
    EditOutlined,
    ExportOutlined,
    WarningOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import PrintProcessing from '@controls/print-processing';
import Toolbar from '@controls/toolbar/toolbar';
import RemainingsPdf from '@print-forms/remainings/remainings-pdf';

import Filter from '@controls/filter/filter';
import FormHeader from '@controls/form-header/form-header';
import Comment from '@controls/comment/comment';

import { delayAction, getDateTimeLocal, getDate } from '@extensions/utils';
import { exception, securityRestriction } from '@extensions/notification';
import { userLoaded, setFilter } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { IRemainingFilter } from '@entities/remaining-filter';
import { IRemaining } from '@entities/remaining';
import { IRemainingBoxGroup } from '@entities/remaining-box-group';
import { ICity } from '@entities/city';
import { Permission, hasPermission } from '@enums/permission';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { IUserSession } from '@entities/user-session';
import { ITruck } from '@entities/truck';
import { color as itemColor } from '@entities/item';

import { HoldIcon } from '@src/core/icons';

dayjs.extend(utc);

interface IOnHoldParams {
    onHoldReason?: string;
}

const filterContext: string = 'Remainings';

const Remainings = () => {
    const initFilter: IRemainingFilter = { cityIds: [], toShippingOnly: true };

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const now = dayjs();

    const { journalId } = useParams();

    const [onHoldForm] = Form.useForm();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IRemainingFilter>((s) => s.filters[filterContext]);

    const d = useAppDispatch();

    const { TextArea } = Input;

    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [headers, setHeaders] = useState<Array<IRemaining>>([]);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [printData, setPrintData] = useState<Array<IRemaining>>();
    const [startPrint, setStartPrint] = useState<boolean>(false);

    const [selectedCityRowKeys, setSelectedCityRowKeys] = useState<React.Key[]>([]);

    const [selectedBoxGroupRowKeys, setSelectedBoxGroupRowKeys] = useState<React.Key[]>([]);
    const [currentBoxGroup, setCurrentBoxGroup] = useState<IRemainingBoxGroup>();
    const [selectedMarkings, setSelectedMarkings] = useState<Array<string>>([]);
    const [selectedTruckIds, setSelectedTruckIds] = useState<Array<string | undefined>>([]);

    const [onHoldOpen, setOnHoldOpen] = useState<boolean>(false);
    const [onHoldProcessing, setOnHoldProcessing] = useState<boolean>(false);

    const [pdfInstance, updatePdf] = usePDF();

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            const promises = [
                await serverFetch('remainings/cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('remainings/trucks', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0] || []);
                setTrucks(result[0][1] || []);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setSelectedCityRowKeys([]);
        setSelectedBoxGroupRowKeys([]);
        setSelectedMarkings([]);
        setSelectedTruckIds([]);

        setHeaders([]);

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('remainings/groups', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения остатков', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setHeaders(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const onGenerateShipmentJournal = () => {
        setLoading(true);

        serverFetch(`shipmentjournals/generate/${journalId}`, {
            method: 'POST',
            bodyData: {
                cityKeys: selectedCityRowKeys,
                boxGroupIds: selectedBoxGroupRowKeys,
                markingCodes: selectedMarkings,
                truckIds: selectedTruckIds,
            },
        })
            .then((data) => {
                setLoading(false);
                navigate(`/warehouse/shipmentjournals/${data}`);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка генерации журнала отгрузки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSaveComment = (boxGroupId: string, comment: string | undefined) => {
        serverFetch(`warehouse/boxgroup/${boxGroupId}/comment`, { method: 'POST', bodyData: comment })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения комментария', ex, () => d(userLoaded(undefined)));
            });
    };

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (printData) {
            updatePdf(<RemainingsPdf remainings={printData} />);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const onSetOnHold = (entity: IOnHoldParams) => {
        setOnHoldProcessing(true);

        serverFetch(`remainings/onhold`, {
            method: 'POST',
            bodyData: {
                ids: selectedBoxGroupRowKeys,
                onHoldReason: entity.onHoldReason,
            },
        })
            .then(() => {
                setOnHoldProcessing(false);

                onHoldForm.resetFields();
                setOnHoldOpen(false);

                setRefreshRequired(true);
            })
            .catch((ex) => {
                setOnHoldProcessing(false);
                exception(api, 'Ошибка блокировки грузополучателя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageWarehouse) ? 'Изменить партию' : 'Информация о партии'
                        }`,
                        key: 'edit-consignment',
                        disabled: !currentBoxGroup,
                        icon: <EditOutlined />,
                        onClick: () => navigate(`/warehouse/remainings/${currentBoxGroup?.boxGroupId}`),
                    },
                    {
                        label: 'Заблокировать / Разблокировать',
                        key: 'onHold',
                        disabled: selectedBoxGroupRowKeys.length <= 0 || loading,
                        icon: <HoldIcon />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }
                            setOnHoldOpen(true);
                        },
                    },
                    {
                        label: 'Печать',
                        key: 'printBarcode',
                        disabled: loading || startPrint,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (
                                !hasPermission(userSession.permissions, Permission.ManageWarehouse) &&
                                !hasPermission(userSession.permissions, Permission.PrintDocuments)
                            ) {
                                securityRestriction(api, [Permission.ManageWarehouse, Permission.PrintDocuments]);
                                return;
                            }

                            setPrintData(headers);
                        },
                    },
                    {
                        label: 'Отгрузка',
                        key: 'shipment',
                        disabled: (selectedCityRowKeys.length < 1 && selectedBoxGroupRowKeys.length < 1) || loading,
                        icon: <ExportOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }
                            onGenerateShipmentJournal();
                        },
                    },
                ]}
                filter={{
                    active: showFilter,
                    onHideFilter: () => {
                        setShowFilter(!showFilter);
                    },
                }}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Select
                            key='truck'
                            placeholder='Машина'
                            value={filter?.truckIds}
                            allowClear={true}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            style={{ width: 150 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, truckIds: value }, filterContext))}
                            optionFilterProp='children'
                            options={trucks.map((t) => {
                                return { value: t.id, label: t.number };
                            })}
                        />,
                        <Select
                            key='city'
                            placeholder='Город доставки'
                            value={filter?.cityIds}
                            allowClear={true}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, cityIds: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        ></Select>,
                        <InputNumber
                            style={{ width: 130 }}
                            key='consigneeCode'
                            placeholder='ID клиента'
                            value={filter?.consigneeCode}
                            onChange={(value) => {
                                d(setFilter({ ...filter, consigneeCode: value }, filterContext));
                            }}
                        />,
                        <Input
                            key='markingCode'
                            style={{ width: 130 }}
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='awbNumber'
                            placeholder='AWB'
                            value={filter?.awbNumber}
                            onChange={(data) => {
                                d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                            }}
                        />,
                        <Form.Item key='toShippingOnly' label='На отгрузку' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.toShippingOnly}
                                onChange={(value) => {
                                    d(setFilter({ ...filter, toShippingOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const onSelectBoxGroupChange = (selectedRowKeys: React.Key[]) => {
        setSelectedCityRowKeys([]);

        setSelectedBoxGroupRowKeys(selectedRowKeys);
        setSelectedMarkings([]);
        setSelectedTruckIds([]);

        const markings: Array<string> = [];
        const truckIds: Array<string | undefined> = [];

        if (selectedRowKeys.length === 1) {
            if (headers) {
                headers.map((h) => {
                    h.remainingBoxGroups.map((b) => {
                        if (b.boxGroupId === selectedRowKeys[0]) {
                            setCurrentBoxGroup(b);
                            markings.push(b.markingCode);
                            truckIds.push(b.truckId);
                        }
                    });
                });
            }
        } else {
            setCurrentBoxGroup(undefined);

            if (headers) {
                headers.map((h) => {
                    h.remainingBoxGroups.map((b) => {
                        const boxGroupId = selectedRowKeys.find((k) => k === b.boxGroupId);
                        if (boxGroupId) {
                            markings.push(b.markingCode);
                            truckIds.push(b.truckId);
                        }
                    });
                });
            }
        }

        setSelectedMarkings(markings);
        setSelectedTruckIds(truckIds);
    };

    const expandedRemainings = (record: IRemaining) => {
        const columns: ColumnsType<IRemainingBoxGroup> = [
            {
                title: 'ID',
                align: 'center',
                width: 80,
                onCell: (record: IRemainingBoxGroup) => ({
                    style: {
                        background: record.consigneeIsLocked ? '#ff9999' : '',
                    },
                }),
                render: (_, record) => {
                    return (
                        <Tooltip placement='top' title={record.consigneeLockReason} color='red'>
                            <div>{record.consigneeCode}</div>
                            <div className='transitWarehouseTag'>{record.warehouseCode}</div>
                        </Tooltip>
                    );
                },
            },
            {
                title: ' ',
                width: 32,
                align: 'center',
                render: (_, record: IRemainingBoxGroup) => {
                    let diffHours: number = 0;
                    if (record.acceptedOn) {
                        diffHours = now.diff(record.acceptedOn, 'hour');
                    }

                    return (
                        <>
                            {record.onHold && (
                                <Tooltip placement='top' title={record.onHoldReason} color='darkorchid'>
                                    <div>
                                        <HoldIcon style={{ color: 'darkorchid', fontSize: 18 }} />
                                    </div>
                                </Tooltip>
                            )}

                            {diffHours >= 24 ? (
                                <Tooltip placement='top' title='Хранение более 24 часов на складе' color='darkorchid'>
                                    <div>
                                        <WarningOutlined style={{ color: 'red', fontSize: 18 }} />
                                    </div>
                                </Tooltip>
                            ) : diffHours >= 10 ? (
                                <Tooltip placement='top' title='Хранение более 10 часов на складе' color='darkorchid'>
                                    <div>
                                        <WarningOutlined style={{ color: 'orange', fontSize: 18 }} />
                                    </div>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </>
                    );
                },
            },
            {
                title: ' ',
                width: 32,
                align: 'center',
                render: (_: any, record: IRemainingBoxGroup) => {
                    return (
                        <Comment
                            editMode={true}
                            comment={record.comment}
                            onSave={(e) => {
                                if (record.boxGroupId) onSaveComment(record.boxGroupId, e);
                            }}
                        />
                    );
                },
            },
            {
                title: 'Желаемая отгрузка',
                dataIndex: 'shippingPriorityOn',
                width: 180,
                align: 'center',
                render: (_, record: IRemainingBoxGroup) => {
                    if (!record.shippingPriorityOn) return;
                    const shippingPriorityOn = dayjs(record.shippingPriorityOn).utc(true).set('hour', 0).set('minute', 0).set('second', 0);

                    const now = dayjs().utc(true).set('hour', 0).set('minute', 0).set('second', 0);
                    let color = 'green';
                    if (shippingPriorityOn == now) {
                        color = 'orange';
                    } else if (shippingPriorityOn < now) {
                        color = 'red';
                    }

                    return (
                        record.awbNumber && (
                            <Tag color={color} style={{ marginLeft: 10, fontWeight: 600, color: '#000000' }}>
                                {getDate(shippingPriorityOn)}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 200,
            },
            {
                title: 'Страна',
                width: 190,
                render: (_, record) => {
                    return (
                        <Flex vertical>
                            {record.countryName}

                            <Tag
                                color={itemColor(record.itemName)}
                                style={{
                                    marginBottom: 2,
                                    paddingBottom: 2,
                                    width: 'fit-content',
                                    fontSize: 15,
                                    fontWeight: 600,
                                }}
                            >
                                {record.itemName}
                            </Tag>
                        </Flex>
                    );
                },
            },

            {
                title: 'AWB',
                width: 200,
                align: 'center',
                render: (_, record) => {
                    return (
                        record.awbNumber && (
                            <Tag color='#efefef' style={{ marginLeft: 10, color: '#000000' }}>
                                {record.awbNumber}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Отгрузка',
                align: 'center',
                width: 100,
                render: (_, record: IRemainingBoxGroup) => {
                    return (
                        record.loadingOn && (
                            <Tag color={record.isOverdue ? 'red' : 'var(--primary-color)'} style={{ marginLeft: 10, color: '#000000' }}>
                                {getDate(record.loadingOn)}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Машина',
                width: 150,
                render: (_, record) => {
                    return record.truckNumber || '- догруз -';
                },
            },
            {
                title: 'Дата отгрузки',
                align: 'center',
                width: 130,
                render: (_, record) => {
                    return getDate(record.departureOn);
                },
            },
            {
                title: 'Дата приемки',
                align: 'center',
                width: 140,
                render: (_: any, record: IRemainingBoxGroup) => {
                    return getDateTimeLocal(record.acceptedOn);
                },
            },
            {
                title: 'Кол-во / шт',
                dataIndex: 'qty',
                align: 'center',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record: IRemainingBoxGroup) => {
                    if (record.boxGroupQty == record.qty) return record.qty;

                    return (
                        <Tooltip title='Расхождение с расчетными документами' color='var(--main-red)'>
                            <span style={{ color: 'var(--main-red)', fontWeight: 600 }}>{record.qty}</span>
                            <span> / {record.boxGroupQty}</span>
                        </Tooltip>
                    );
                },
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record: IRemainingBoxGroup) => {
                    return record.calcVolume?.toFixed(2);
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='boxGroupId'
                size='small'
                columns={columns}
                dataSource={record.remainingBoxGroups}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedBoxGroupRowKeys,
                    onChange: onSelectBoxGroupChange,
                    columnWidth: 32,
                }}
            />
        );
    };

    const onSelectCityChange = (selectedRowKeys: React.Key[]) => {
        setSelectedCityRowKeys(selectedRowKeys);
        setSelectedBoxGroupRowKeys([]);
        setSelectedMarkings([]);
        setSelectedTruckIds([]);
    };

    const renderTable = () => {
        const columns: ColumnsType<IRemaining> = [
            {
                title: 'Город доставки',
                dataIndex: 'cityName',
                width: 500,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record: IRemaining) => {
                    let diffHours: number = 0;
                    if (record.acceptedOn) {
                        diffHours = now.diff(record.acceptedOn, 'hour');
                    }

                    return (
                        <>
                            <span>{record.cityName}</span>

                            {record.warehouseCode && (
                                <Tag color='#3e4657' style={{ marginLeft: 10, color: '#ffffff' }}>
                                    {record.warehouseCode}
                                </Tag>
                            )}

                            {record.billCityName != record.cityName && (
                                <Tag color='#FFA500' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.billCityName} - самовывоз
                                </Tag>
                            )}

                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}

                            {diffHours >= 24 ? (
                                <WarningOutlined style={{ color: 'red', fontSize: 18, marginLeft: 5 }} />
                            ) : diffHours >= 10 ? (
                                <WarningOutlined style={{ color: 'orange', fontSize: 18, marginLeft: 5 }} />
                            ) : (
                                <></>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                dataIndex: 'totalQty',
                key: 'totalQty',
                width: 120,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
                render: (_: any, record: IRemaining) => {
                    return record.totalVolume.toFixed(2);
                },
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => expandedRemainings(record),
                    columnWidth: 32,
                }}
                dataSource={headers}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedCityRowKeys,
                    onChange: onSelectCityChange,
                    columnWidth: 32,
                }}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <>
            <div ref={containerRef} style={{ height: `calc(100vh - 182px - ${filterHeight}px)` }}>
                {journalId && <FormHeader title={`Добавить в журнал`} />}
                {renderToolbar()}
                {renderFilter()}
                {headers && renderTable()}

                {startPrint && <PrintProcessing />}

                {contextHolder}
            </div>

            <Modal
                title={
                    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'start' }}>
                        <QuestionCircleOutlined style={{ color: '#faad14', marginInlineEnd: 12, fontSize: 22 }} />
                        {`Заблокировать / разблокировать выделенные позиции?`}
                    </div>
                }
                open={onHoldOpen}
                okText='Сохранить'
                onOk={() => onHoldForm.submit()}
                onCancel={() => {
                    onHoldForm.resetFields();
                    setOnHoldOpen(false);
                }}
                closable={false}
                cancelButtonProps={{ style: { display: onHoldProcessing ? 'none' : '' } }}
                confirmLoading={onHoldProcessing}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onSetOnHold}
                    form={onHoldForm}
                    style={{ marginTop: 10 }}
                >
                    <Form.Item
                        required={true}
                        label='Причина'
                        name='onHoldReason'
                        rules={[{ required: true, message: 'Укажите причину блокировки' }]}
                    >
                        <TextArea autoFocus rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Remainings;
