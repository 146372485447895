import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';
import { v4 as uuid } from 'uuid';

import { Table, notification, Input, Tag, InputNumber, Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    LoadingOutlined,
    ReloadOutlined,
    UnorderedListOutlined,
    BarcodeOutlined,
    PrinterOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import ConsignmentCard, { IBoxGroupCardItem } from '@controls/consignment-card/consignment-card';
import PrintProcessing from '@controls/print-processing';
import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';

import BarcodePdf from '@print-forms//barcode-pdf';

import { delayAction } from '@extensions/utils';
import { exception, error, securityRestriction } from '@extensions/notification';
import { userLoaded, setFilter } from '@store/actions';

import { IUserSession } from '@entities/user-session';
import { IConsignment } from '@entities/consignment';
import { IConsignmentFilter } from '@entities/consignment-filter';
import { ICountry } from '@entities/country';

import { AreaType } from '@enums/area-type';
import { Permission, hasPermission } from '@enums/permission';
import { WarehouseType } from '@enums/warehouse-type';

import { serverFetch } from '@src/core/server';

const filterContext: string = 'Consignments';

const Consignments = ({ ...props }) => {
    const { truckId, viewOnly } = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const initFilter: IConsignmentFilter = {
        truckIds: [truckId],
        checkBoxNumbersExists: true,
        includeBoxes: true,
        useWarehouseRequired: true,
    };

    const d = useAppDispatch();
    const navigate = useNavigate();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const warehouseType = useAppSelector<WarehouseType>((s) => s.warehouseType);
    const filter = useAppSelector<IConsignmentFilter>((s) => s.filters[filterContext]);

    const [api, contextHolder] = notification.useNotification();

    const [consignments, setConsignments] = useState<Array<IConsignment>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentConsignment, setCurrentConsignment] = useState<IConsignment>();
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [printData, setPrintData] = useState();
    const [startPrint, setStartPrint] = useState<boolean>(false);
    const [loadingPrintData, setLoadingPrintData] = useState<boolean>(false);

    const [pdfInstance, updatePdf] = usePDF();

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setCountries([]);
        setConsignments([]);

        const fetchData = async () => {
            setLoading(true);

            const promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (!warehouseType || warehouseType === WarehouseType.Main) {
                promises.push(
                    await serverFetch(`consignments`, { method: 'GET', queryParams: filter })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
                        })
                );
            } else {
                promises.push(
                    await serverFetch(`transitwarehouse/acceptance/consignments`, { method: 'GET', queryParams: filter })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);

                const entities = result[0][1];
                setConsignments(entities);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter || !filter.truckIds?.find((t) => t === truckId)) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (printData) {
            updatePdf(<BarcodePdf labels={printData} />);
            setLoadingPrintData(false);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const getPrintData = () => {
        setLoadingPrintData(true);

        serverFetch(`warehouse/acceptance/${truckId}/print`, { method: 'GET' })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                setLoadingPrintData(false);
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading || !truckId,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Детали партии',
                        key: 'show',
                        disabled: !currentConsignment,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            if (!warehouseType || warehouseType === WarehouseType.Main) {
                                navigate(`/warehouse/acceptance/consignments/${truckId}/${currentConsignment?.id}`);
                            } else {
                                navigate(`/warehouse/acceptance/consignments/${truckId}/${currentConsignment?.markingCode}`);
                            }
                        },
                    },
                    {
                        label: 'Печатать штрих-коды',
                        key: 'printBarcode',
                        disabled: loading || startPrint,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            const canPrint = consignments.every((e) => e.hasBoxNumbers);
                            if (!canPrint) {
                                error(api, 'Сгенерируйте штрих-коды');
                                return;
                            }

                            getPrintData();
                        },
                    },
                    {
                        label: 'Назначить исполнителя',
                        key: 'assignJob',
                        disabled: !currentConsignment,
                        icon: <UsergroupAddOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            navigate(`/warehouse/performers/${AreaType.Acceptance}/${truckId}`);
                        },
                    },
                ]}
                filter={{
                    active: showFilter,
                    onHideFilter: () => {
                        setShowFilter(!showFilter);
                    },
                }}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        if (viewOnly) return;

        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length === 1) {
            const entity = consignments.find((o) => o.id === selectedRowKeys[0]);
            setCurrentConsignment(entity);
        } else {
            setCurrentConsignment(undefined);
        }
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <InputNumber
                            style={{ width: 130 }}
                            key='consigneeCode'
                            placeholder='ID клиента'
                            value={filter?.consigneeCode}
                            onChange={(value) => {
                                d(setFilter({ ...filter, consigneeCode: value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='awbNumber'
                            placeholder='AWB'
                            value={filter?.awbNumber}
                            onChange={(data) => {
                                d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderTable = () => {
        const lines = consignments.map((c) => {
            const boxGroups: Array<any> = [];
            let totalQty = 0;
            let totalScannedQty = 0;

            c.boxGroups?.map((b) => {
                boxGroups[b.countryId as any] = { qty: b.actualBoxQty, scannedBoxQty: b.scannedBoxQty, awbNumber: b.awbNumber };
                totalQty += b.actualBoxQty || 0;
                totalScannedQty += b.scannedBoxQty || 0;
            });

            c = {
                ...c,
                ...boxGroups,
                totalQty: totalQty,
                totalScannedQty: totalScannedQty,
            };
            return c;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                width: 80,
                align: 'center',
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 200,
            },
            {
                title: 'Город доставки',
                width: 250,
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.cityName}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Транзит',
                width: 80,
                render: (_, record) => {
                    return (
                        <>
                            <div className='transitWarehouseTag'>{record.warehouseCode}</div>
                        </>
                    );
                },
            },
            {
                dataIndex: 'countries',
                width: 375,
                render: (_, record) => {
                    return (
                        <Flex vertical={true}>
                            {countries.map((c) => {
                                const boxGroups = record.boxGroups?.filter((bg) => bg.countryId === c.id);
                                if (!boxGroups || boxGroups.length <= 0) return <div key={uuid()}></div>;

                                return (
                                    <ConsignmentCard
                                        key={uuid()}
                                        country={c}
                                        api={api}
                                        items={boxGroups as Array<IBoxGroupCardItem>}
                                        useScanData={true}
                                        allowComment={true}
                                        onRefresh={() => setRefreshRequired(true)}
                                    />
                                );
                            })}
                        </Flex>
                    );
                },
            },

            {
                title: 'Кол-во / шт',
                align: 'center',
                dataIndex: 'totalQty',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span style={{ fontWeight: 600, color: record.totalScannedQty === record.totalQty ? '#228B22' : 'red' }}>
                                {record.totalScannedQty}
                            </span>
                            <span> / </span>
                            <span>{record.totalQty}</span>
                        </>
                    );
                },
            },
            {
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return record.hasBoxNumbers && <BarcodeOutlined />;
                },
            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                width: 300,
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={
                    viewOnly
                        ? undefined
                        : {
                              selectedRowKeys: selectedIds,
                              onChange: onSelectChange,
                              type: 'radio',
                              columnWidth: 32,
                          }
                }
                onRow={(record) => {
                    return {
                        onClick: (e) => {
                            if ((e.target as Element).className.includes('ant-table')) onSelectChange([record.id || '']);
                        },
                    };
                }}
                columns={columns}
                dataSource={lines}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                pagination={false}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58), x: 'max-content' }}
                virtual
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 415px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}
            {(loadingPrintData || startPrint) && <PrintProcessing />}

            {contextHolder}
        </div>
    );
};

export default Consignments;
