import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Form, Input } from 'antd';

import PhoneInput, { isValidPhoneNumber, Value } from 'react-phone-number-input';
import ru from 'react-phone-number-input/locale/ru.json';

import 'react-phone-number-input/style.css';
import './phone-input-form-item.css';

interface IPhoneInputFormItemProps {
    value: string | undefined;
    name: string;
    label: string;
    required?: boolean;
    disabled?: boolean;
    //  initialValue: string | undefined;
    onChange?: (e: string | undefined) => void;
}

const PhoneInputFormItem = (props: IPhoneInputFormItemProps) => {
    const { value, name, label, required, disabled, onChange } = props;

    const [buffPhone, setBuffPhone] = useState<Value | undefined>(value as Value);

    useEffect(() => {
        
    }, [buffPhone]);

    const CustomInput = React.forwardRef((props, ref) => {
        const inputRef = useRef<any>(null);

        useImperativeHandle(
            ref,
            () => {
                return inputRef.current.input;
            },
            []
        );
        return (
            <Input
                {...props}
                ref={inputRef}
                onChange={(e) => {
                    setBuffPhone(e.target.value as Value);
                    console.log('onChange', e.target.value);
                }}
                onBlur={(e) => {
                    //onChange(e.target.value);
                    console.log('onBlur', e.target.value);
                }}
            />
        );
    });

    return (
        <Form.Item
            initialValue={value}
            required={required}
            name={name}
            label={label}
            rules={[
                { required: required, message: 'Укажите телефон' },
                {
                    validator: async (_, value) => {
                        if (value == undefined) return Promise.resolve();

                        if (!isValidPhoneNumber(value)) return Promise.reject('Неверный формат телефона');
                        return Promise.resolve();
                    },
                },
            ]}
        >
            <PhoneInput
                international
                labels={ru}
                defaultCountry='RU'
                disabled={disabled}
                onChange={(e) => {
                    if (onChange) onChange(e);
                }}
                // inputComponent={CustomInput}
            />
        </Form.Item>
    );
};

export default PhoneInputFormItem;
