import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Modal, Table, Button, Col, Row, Input, Tooltip, Form, Popconfirm, Tag, Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { notification } from 'antd';

import {
    LoadingOutlined,
    PlusOutlined,
    CheckOutlined,
    PrinterOutlined,
    ReloadOutlined,
    QuestionCircleOutlined,
    DeleteFilled,
} from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import PrintProcessing from '@controls/print-processing';
import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';
import BarcodePdf from '@print-forms/barcode-pdf';

import { exception, securityRestriction } from '@extensions/notification';
import { userLoaded } from '@store/actions';

import { IUserSession } from '@entities/user-session';
import { IConsignment } from '@entities/consignment';
import { IBoxGroup } from '@entities/box-group';
import { IBox } from '@entities/box';
import { IDeleteBoxesParams } from '@entities/delete-boxes-params';
import { color as itemColor } from '@entities/item';

import { Permission, hasPermission } from '@enums/permission';
import { WarehouseType } from '@enums/warehouse-type';

import { serverFetch } from '@src/core/server';

const WarehouseConsignment = () => {
    const { TextArea } = Input;

    const [deleteBoxForm] = Form.useForm();

    const { truckId, markingCode, consignmentId } = useParams();

    const navigate = useNavigate();
    const d = useAppDispatch();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const warehouseType = useAppSelector<WarehouseType>((s) => s.warehouseType);

    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const [entity, setEntity] = useState<IConsignment>();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [allBoxesAccepted, setAllBoxesAccepted] = useState<boolean>(false);
    const [openDeleteBoxModal, setOpenDeleteBoxModal] = useState<boolean>(false);

    const [printData, setPrintData] = useState<any>();
    const [startPrint, setStartPrint] = useState<boolean>(false);

    const [pdfInstance, updatePdf] = usePDF();

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        if (!warehouseType || warehouseType === WarehouseType.Main) {
            serverFetch(`consignments/${consignmentId}`, {
                method: 'GET',
                queryParams: { truckId: truckId, markingCode: markingCode },
            })
                .then((data: IConsignment) => {
                    setEntity(data);

                    let allAccepted = true;

                    if (data.boxGroups) {
                        data.boxGroups.map((g) => {
                            if (g.boxes) {
                                g.boxes.map((b) => {
                                    if (!b.acceptedOn) {
                                        allAccepted = false;
                                        return;
                                    }
                                });
                            }

                            if (!allAccepted) return;
                        });
                    }

                    setAllBoxesAccepted(allAccepted);
                })
                .catch((ex) => {
                    exception(api, 'Ошибка получения деталей груза', ex, () => d(userLoaded(undefined)));
                });
        } else {
            serverFetch(`transitwarehouse/acceptance/consignment`, {
                method: 'GET',
                queryParams: { truckIds: [truckId], markingCode: markingCode },
            })
                .then((data: IConsignment) => {
                    setEntity(data);

                    let allAccepted = true;

                    if (data.boxGroups) {
                        data.boxGroups.map((g) => {
                            if (g.boxes) {
                                g.boxes.map((b) => {
                                    if (!b.acceptedOn) {
                                        allAccepted = false;
                                        return;
                                    }
                                });
                            }

                            if (!allAccepted) return;
                        });
                    }

                    setAllBoxesAccepted(allAccepted);
                })
                .catch((ex) => {
                    exception(api, 'Ошибка получения деталей груза', ex, () => d(userLoaded(undefined)));
                });
        }
    }, [refreshRequired]);

    useEffect(() => {
        if (printData) {
            updatePdf(<BarcodePdf labels={printData} />);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const getPrintData = (ids: Array<string>) => {
        serverFetch(`warehouse/box/print`, { method: 'POST', bodyData: ids })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const onAdd = (boxGroupId: string | undefined) => {
        if (!boxGroupId) return;

        setLoading(true);

        serverFetch(`warehouse/box/${boxGroupId}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка добавления коробки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onAcceptBox = (boxId: string) => {
        setLoading(true);

        serverFetch(`warehouse/acceptbox/${boxId}/${entity?.truckId}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка приемки коробки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteBoxes = (data: IDeleteBoxesParams) => {
        serverFetch(`warehouse/boxes`, { method: 'DELETE', bodyData: data })
            .then(() => {
                deleteBoxForm.resetFields();
                setOpenDeleteBoxModal(false);

                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления коробки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCompleteConsignment = () => {
        setLoading(true);

        serverFetch(`warehouse/${consignmentId}/completeconsignment`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка завершения партии', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                ]}
            />
        );
    };

    const expandedBoxes = (record: IBoxGroup) => {
        const columns: ColumnsType<IBox> = [
            {
                title: ' ',
                key: 'boxAction',
                align: 'center',
                width: 80,
                render: (_, record) => {
                    return (
                        <Flex gap='small'>
                            {hasPermission(userSession.permissions, Permission.AcceptBox) && (
                                <Popconfirm
                                    title={`Принять коробку "${record.number}"  на баланс ?`}
                                    onConfirm={() => {
                                        if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                            securityRestriction(api, [Permission.ManageWarehouse]);
                                            return;
                                        }

                                        onAcceptBox(record.id);
                                    }}
                                >
                                    <Button
                                        type={!record.acceptedOn ? 'primary' : 'default'}
                                        disabled={!!record.acceptedOn}
                                        icon={<CheckOutlined />}
                                        size={'small'}
                                    />
                                </Popconfirm>
                            )}

                            {hasPermission(userSession.permissions, Permission.DeleteRemainingBox) && (
                                <Popconfirm
                                    title={`Удалить коробку "${record.number}"?`}
                                    onConfirm={() => {
                                        deleteBoxForm.setFieldValue('ids', [record.id]);
                                        setOpenDeleteBoxModal(true);
                                    }}
                                >
                                    <Button icon={<DeleteFilled />} size={'small'} />
                                </Popconfirm>
                            )}

                            <Tooltip placement='topRight' title='Печатать штрих-коды'>
                                <Button
                                    icon={<PrinterOutlined />}
                                    size={'small'}
                                    onClick={() => {
                                        if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                            securityRestriction(api, [Permission.ManageWarehouse]);
                                            return;
                                        }

                                        getPrintData([record.id]);
                                    }}
                                />
                            </Tooltip>
                        </Flex>
                    );
                },
            },
            {
                title: 'Номер',
                dataIndex: 'number',
                width: 200,
            },
            {
                title: 'Продукт',
                dataIndex: 'number',
                width: 200,
                render: (_, record) => {
                    return (
                        <Tag
                            color={itemColor(record.itemName)}
                            style={{
                                marginBottom: 2,
                                paddingBottom: 2,
                                width: 'fit-content',
                                fontSize: 15,
                                fontWeight: 600,
                            }}
                        >
                            {record.itemName}
                        </Tag>
                    );
                },
            },
            {
                title: 'Принят',
                dataIndex: 'number',
                width: 100,
                align: 'center',
                render: (_, record) => {
                    return record.acceptedOn && <CheckOutlined />;
                },
            },
            {},
        ];

        return <Table rowKey='id' size='small' columns={columns} dataSource={record.boxes} pagination={false} />;
    };

    const renderTable = () => {
        const columns: ColumnsType<IBoxGroup> = [
            {
                title: 'Страна',
                dataIndex: 'countryName',
                key: 'countryName',
            },
            {
                key: 'groupAction',
                width: 55,
                onCell: () => ({
                    style: {
                        padding: 0,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <Tooltip placement='topRight' title='Добавить новую коробку'>
                                <Button
                                    type='primary'
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                            securityRestriction(api, [Permission.ManageWarehouse]);
                                            return;
                                        }

                                        modal.confirm({
                                            title: `Добавить коробку?`,
                                            okType: 'primary',
                                            icon: <QuestionCircleOutlined />,
                                            okText: 'Добавить',
                                            cancelText: 'Отмена',
                                            onOk: () => {
                                                if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                                    securityRestriction(api, [Permission.ManageWarehouse]);
                                                    return;
                                                }

                                                onAdd(record.id);
                                            },
                                        });
                                    }}
                                />
                            </Tooltip>
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span style={{ fontWeight: 600, color: record.scannedBoxQty === record.actualBoxQty ? '#228B22' : 'red' }}>
                                {record.scannedBoxQty}
                            </span>
                            <span> / </span>
                            <span>{record.actualBoxQty}</span>
                        </>
                    );
                },
            },
        ];

        return (
            entity?.boxGroups && (
                <Table
                    showHeader={false}
                    rowKey='id'
                    size='small'
                    loading={{
                        spinning: loading,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    columns={columns}
                    expandable={{
                        columnWidth: 32,
                        expandedRowRender: expandedBoxes,
                        defaultExpandedRowKeys: entity?.boxGroups?.map((b) => b.id) as Array<React.Key>,
                    }}
                    dataSource={entity.boxGroups || []}
                    pagination={false}
                    scroll={{ y: `calc(100vh - 150px)` }}
                />
            )
        );
    };

    return (
        <>
            <Row>
                <Col span={9}>
                    <Row>
                        <FormHeader title='Детали партии' />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <Form.Item label='ID'>
                                    <Input disabled value={entity?.consigneeCode} />
                                </Form.Item>
                                <Form.Item label='Маркировка'>
                                    <Input disabled value={entity?.markingCode} />
                                </Form.Item>
                                <Form.Item label='Комментарий'>
                                    <TextArea disabled rows={4} value={entity?.comment} />
                                </Form.Item>
                                {hasPermission(userSession.permissions, Permission.FullAccess) && (
                                    <Form.Item wrapperCol={{ offset: 11 }}>
                                        <Button
                                            style={{ float: 'right' }}
                                            type='primary'
                                            htmlType='submit'
                                            onClick={() => {
                                                if (!allBoxesAccepted) {
                                                    modal.confirm({
                                                        title: `Не все коробки обработаны. Хотите завершить приемку?`,
                                                        okType: 'primary',
                                                        icon: <QuestionCircleOutlined />,
                                                        okText: 'Завершить',
                                                        cancelText: 'Отмена',
                                                        onOk: () => {
                                                            onCompleteConsignment();
                                                        },
                                                    });
                                                } else {
                                                    onCompleteConsignment();
                                                }
                                            }}
                                        >
                                            Завершить
                                        </Button>
                                    </Form.Item>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col offset={1} span={14}>
                    <div style={{ marginTop: 10 }}>
                        {renderToolbar()}
                        {!loading && renderTable()}
                    </div>
                </Col>
                {contextHolder}
                {modalContextHolder}
            </Row>

            <Modal
                width={400}
                title='Причина удаление коробки'
                open={openDeleteBoxModal}
                okText='ОК'
                closable={false}
                onOk={() => {
                    deleteBoxForm.submit();
                }}
                onCancel={() => {
                    deleteBoxForm.resetFields();
                    setOpenDeleteBoxModal(false);
                }}
            >
                <Form colon={false} wrapperCol={{ span: 24 }} form={deleteBoxForm} onFinish={onDeleteBoxes}>
                    <Form.Item name='ids' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='reason' required rules={[{ required: true, message: 'Укажите причину удаления' }]}>
                        <TextArea rows={6} />
                    </Form.Item>
                </Form>
            </Modal>

            {startPrint && <PrintProcessing />}
        </>
    );
};

export default WarehouseConsignment;
